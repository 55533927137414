class XoneDebugTools {
	GetDeviceID() {
		return localStorage.getItem("_deviceId");
	}
	GetLog() {}
	SendDatabase() {}
	SendLog() {}
	SendReplicaDebugDatabase() {}
	SendReplicaFilesDatabase() {}
}

export default XoneDebugTools;
