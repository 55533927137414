import Hashtable from "../Collections/HashMap/Hashtable";
import IConnection from "../Interfaces/IConnection";
import IFieldProperties from "../Interfaces/IField";
import IMessageHolder from "../Interfaces/IMessageHolder";
import IResultSet from "../Interfaces/IResultSet";
import IXmlNode from "../Interfaces/IXmlNode";
import IXoneApp from "../Interfaces/IXoneApp";
import IXoneObject from "../Interfaces/IXoneObject";
import Calendar from "../Utils/Calendar";
import ObjectDeveloper from "../Utils/ObjectDeveloper";
import ObjUtils from "../Utils/ObjUtils";
import StringUtils from "../Utils/StringUtils";
import { Utils } from "../Utils/Utils";
import XoneConnDataFlags from "./XoneConnDataFlags";
import { Guid } from "../Utils/Guid";
import { SqlParser } from "../Parsers/SQL/SqlParser";

export default abstract class XoneConnectionData {
    PrepareFilter(strSQL: string): string {
        throw new Error("Method not implemented.");
    }

    // TAG 20050205: Luis: exponer metodos script en esta clase
    //private static final ArrayList<Method> lstScriptAllowedMethods = WrapReflection.SafeGetAnnotatedMethods(XoneConnectionData.class, ScriptAllowed.class);
    /**
     * Constante para exponer el nombre de la conexión principal
     */
    public static MAIN_CONNECTION = "##MAIN_CONNECTION##";
    /**
     * Nombre de la conexión
     */
    protected m_strName = "";
    /**
     * Prefijo de las tablas con esta conexión.
     */
    protected m_strPrefix = "";
    /**
     * Almacena los datos del nodo XML de la conexión por si se necesitan para algo
     */
    protected m_xmlNodeData: IXmlNode;
    /**
     * Cadena de conexión para acceder a los datos
     */
    protected m_strConnString: string;
    /**
     * Subqueries si la conexión tiene que replicar
     */
    protected m_bIsReplicating: boolean;
    /**
     * MID para la réplica si se ha leído del registro
     */
    protected m_nMid: number;
    /**
     * DBID para la réplica si se ha leído del registro
     */
    protected m_nDbId: number;
    /**
     * Nombre del campo ROWID en esta conexión
     */
    protected m_strRowIdFieldName: string;
    /**
     * Nombre del campo SQL en esta conexión
     */
    protected m_strSqlFieldName: string;
    /**
     * Subqueries si la conexión tiene campo TBL en la cola de réplica
     */
    protected m_bHasTblField: boolean;
    /**
     * Tamaño del OPERID para la conexión
     */
    protected m_nOperIdLength: number;
    /**
     * Tamaño del ROWID para la conexión
     */
    protected m_nRowIdLength: number;
    /**
     * Aplicación propietaria de esta conexión
     */
    protected m_owner: IXoneApp;
    /**
     * Lista de cadenas de inicialización de esta conexión
     */
    protected m_lstInitStrings: Array<string>;
    /**
     * Aquí se almacena el Datemask que venga de fuera. Si al cargar el nodo DBMS es NULL se calcula de dicho nodo, de lo contrario se respeta este.
     */
    protected m_strDatemask: string;
    /**
     * Banderas que indican la modificación de determinados campos de la conexión.
     */
    protected m_nFlags: number;
    /**
     * Separador para encerrar nombres de tablas (apertura)
     */
    protected m_strTableQuoteOpen = "";
    /**
     * Separador para encerrar nombres de tablas (cierre)
     */
    protected m_strTableQuoteClose = "";
    /**
     * Separador para encerrar nombres de campos (apertura)
     */
    protected m_strFieldQuoteOpen = "";
    /**
     * Separador para encerrar nombres de campos (cierre)
     */
    protected m_strFieldQuoteClose = "";
    /**
     * TRUE si la conexión soporta subqueries para la ejecución de SELECTS
     */
    protected m_bSubqueries: boolean;
    /**
     * M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
     * Aquí almacenamos un gestor de mensajes por si las moscas... igual nos hace falta.
     */
    protected m_messages: IMessageHolder;
    /**
     * A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
     * TRUE si la conexión soporta las transacciones.
     */
    protected m_bSupportsTransactions: boolean;
    /**
     * A11111101: Soportar plataformas a nivel de nodos de conexión.
     * Nombre de la plataforma, si es que la tenemos
     */
    protected m_strPlatform: string;
    /**
     * A11111101: Soportar plataformas a nivel de nodos de conexión.
     * Si tenemos plataforma y hay nodo platform, lo ponemos aquí.
     */
    protected m_platform: IXmlNode;

    /*
     * ADD TAG TODO: el developer object value, lo traemos a la clase base de los data connection
     */
    /**
     * Este objeto se encargaría de gestionar el desarrollo de objetos en forma de cadena.
     */
    protected m_developer: ObjectDeveloper;


    // A13080703: Mecanismo para adicionar propiedades extendidas a las conexiones.
    protected m_extendedProperties: Hashtable<string, Object>;

    /**
     * Crea una conexión con el nombre que se le indica.
     *
     * @param Name  Nombre de la conexión a crear.
     * @param Owner Aplicación a la cual pertenece la conexión.
     */
    constructor(Name: string, Owner: IXoneApp) {
        if (StringUtils.IsEmptyString(Name)) {
            this.m_strName = XoneConnectionData.MAIN_CONNECTION;
        } else {
            this.m_strName = Name;
        }
        // Guardar el propietario
        this.m_owner = Owner;
        // ADD TAG Crear el objeto para el developer
        this.m_developer = new ObjectDeveloper();
        // Valores por defectos para los nombres de los campos y demás
        this.m_strRowIdFieldName = "ROWID";
        this.m_strSqlFieldName = "SQL";

        //        this.m_nOperIdLength = 50;
        /*
         * TODO ADD TAG 04/05/2015 Juan Carlos:
         * Subo el límite de 50 a 100, en principio
         * es código muy viejo y realmente ni hace falta.
         */
        this.m_nOperIdLength = 100;

        this.m_nRowIdLength = 35;
        // Crear la lista de InitStrings
        this.m_lstInitStrings = new Array<string>();
        // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
        this.m_messages = Owner.getMessageHolder();
        // A13080703: Mecanismo para adicionar propiedades extendidas a las conexiones.
        this.m_extendedProperties = new Hashtable<string, Object>();
        // TAG 20050205: Luis: exponer metodos script en esta clase
        //this.addJavascriptFunctions();
    }

    // A13080703: Mecanismo para adicionar propiedades extendidas a las conexiones.
    // TAG 20050204: Luis: exponer en los script las propiedades extendidas del conmstring para tratarlas
    // esto permite agregar dinamicamente por script propiedades al connstring
    //@ScriptAllowed
    public addExtendedProperty(key: string, value: Object): number {
        if (this.m_extendedProperties == null) {
            this.m_extendedProperties = new Hashtable<string, Object>();
        }
        this.m_extendedProperties.put(key, value);
        return 0;
    }

    // TAG 20050204: Luis: exponer en los script las propiedades extendidas del conmstring para tratarlas
    // esto permite agregar dinamicamente por script propiedades al connstring
    //@ScriptAllowed
    public removeExtendedProperty(key: string): number {
        if (this.m_extendedProperties != null) {
            if (this.m_extendedProperties.containsKey(key)) {
                this.m_extendedProperties.delete(key);
            }
        }
        return 0;
    }

    // TAG 20050204: Luis: exponer en los script las propiedades extendidas del conmstring para tratarlas
    // esto permite agregar dinamicamente por script propiedades al connstring
    //@ScriptAllowed
    public getExtendedProperty(key: string): Object {
        if (this.m_extendedProperties == null) {
            return null;
        }
        if (!this.m_extendedProperties.containsKey(key)) {
            return null;
        }
        return this.m_extendedProperties.get(key);
    }

    public getExtendedList(): Hashtable<string, Object> {
        return this.m_extendedProperties;
    }

    /**
     * Expone el nombre de la conexión.
     *
     * @return Devuelve el nombre de la conexión.
     */
    public getName(): string {
        return this.m_strName;
    }

    /**
     * Expone o asigna valor al prefijo de las tablas en esta conexión.
     *
     * @return Devuelve el prefijo de las tablas en esta conexión.
     */
    public getPrefix(): string {
        return this.m_strPrefix;
    }

    /**
     * Asigna valor al prefijo de datos de las tablas en esta conexión.
     *
     * @param value Nuevo valor del prefijo.
     */
    public setPrefix(value: string): void {
        this.m_strPrefix = value;
        this.m_nFlags |= XoneConnDataFlags.PREFIX_MODIFIED;
    }

    /**
     * Expone o asigna valor al nodo XML con los datos de la conexión
     *
     * @return Devuelve el nodo XML del que se hayan sacado los datos de esta conexión.
     */
    public getNodeData(): IXmlNode {
        return this.m_xmlNodeData;
    }

    /**
     * A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
     *
     * @return TRUE si la conexión soporta transacciones.
     */
    public supportsTransactions(): boolean {
        return this.m_bSupportsTransactions;
    }

    /**
     * Asigna un nodo de datos XML para sacar de él los datos de declaración de la conexión
     *
     * @param value Nodo XML con los datos de la conexión.
     */
    public setNodeData(value: IXmlNode): void {
        if (null != (this.m_xmlNodeData = value)) {// Sacar algunas cosas del nodo
            // A11111101: Soportar plataformas a nivel de nodos de conexión.
            // Comprobar si tiene nodo platform
            if (!StringUtils.IsEmptyString(this.m_strPlatform)) {
                this.m_platform = this.m_xmlNodeData.SelectSingleNode("platform", "name", this.m_strPlatform);
            }

            let str = "";
            // A11111101: Soportar plataformas a nivel de nodos de conexión.
            // Sacar los valores usando GetNodeValue
            // El prefijo se puede definir según la sintaxis antigua
            //////if (!StringUtils.IsEmptyString(str = XmlUtils.GetNodeAttr(this.m_xmlNodeData, "prefix")))
            if (!StringUtils.IsEmptyString(str = this.GetNodeValue("prefix"))) {
                this.setPrefix(str);
            }
            // Los tags para encerrar nombres de campos y tablas
            //////if (!StringUtils.IsEmptyString(str = XmlUtils.GetNodeAttr(this.m_xmlNodeData, "tablequote")))
            if (!StringUtils.IsEmptyString(str = this.GetNodeValue("tablequote"))) {// Sacar los datos
                if (str.length == 1) {// Los dos son iguales
                    this.setTableQuoteOpen(str);
                    this.setTableQuoteClose(str);
                }// Los dos son iguales
                else {// Diferentes
                    this.setTableQuoteOpen(str.substring(0, 1));
                    // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                    this.setTableQuoteClose(str.substring(1, str.length));
                }// Diferentes
            }// Sacar los datos
            //////if (!StringUtils.IsEmptyString(str = XmlUtils.GetNodeAttr(this.m_xmlNodeData, "fieldquote")))
            if (!StringUtils.IsEmptyString(str = this.GetNodeValue("fieldquote"))) {// Sacar los datos
                if (str.length == 1) {// Los dos son iguales
                    this.setFieldQuoteOpen(str);
                    this.setFieldQuoteClose(str);
                }// Los dos son iguales
                else {// Diferentes
                    this.setTableQuoteOpen(str.substring(0, 1));
                    // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                    this.setTableQuoteClose(str.substring(1, str.length));
                }// Diferentes
            }// Sacar los datos
            // A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
            // Ver si soportamos transacciones o no
            //////m_bSupportsTransactions =StringUtils.ParseBoolValue(this.m_xmlNodeData.getAttrValue("transactions"), false);
            this.m_bSupportsTransactions = StringUtils.ParseBoolValue(this.GetNodeValue("transactions"), false);
            // F11041502:	Como parte  de la asignación del nodo XML sacar la cadena de conexión.
            //////if (!StringUtils.IsEmptyString(str =this.m_xmlNodeData.getAttrValue("connstring")))
            if (!StringUtils.IsEmptyString(str = this.GetNodeValue("connstring"))) {
                this.m_strConnString = str;
            }
            if (!StringUtils.IsEmptyString(str = this.GetNodeValue("datemask"))) {
                this.setDatemask(str);
            }
        }// Sacar algunas cosas del nodo
    }

    /**
     * Expone o asigna valor a la cadena de conexión
     *
     * @return Devuelve la cadena de conexión para este objeto.
     */
    // TAG 20050207: Luis: exponer el connstring a la humanidad para que puedan utilizarlo
    //@ScriptAllowed
    public getConnString(): string {
        return this.m_strConnString;
    }

    /**
     * Asigna valor a la cadena de conexión de este objeto.
     *
     * @param value Nuevo valor para la cadena de conexión.
     */
    // TAG 20050207: Luis: exponer el connstring a la humanidad para que puedan utilizarlo
    //@ScriptAllowed
    public setConnString(value: string): void {
        this.m_strConnString = value;
        this.m_nFlags |= XoneConnDataFlags.CONNSTRING_MODIFIED;
    }

    /**
     * Expone o asigna valor a la banderilla que indica si la conexión replica o no.
     *
     * @return Devuelve la bandera que indica si esta conexión está replicando o no.
     */
    public getIsReplicating(): boolean {
        return this.m_bIsReplicating;
    }

    /**
     * Asigna valor a la bandera que indica si la conexión está replicando o no.
     *
     * @param value TRUE para indicar que la conexión replica a la hora de ejecutar sentencias SQL.
     */
    public setIsReplicating(value: boolean): void {
        this.m_bIsReplicating = value;
    }

    /**
     * Expone o asigna valor al MID que va a usar esta conexión para replicar
     *
     * @return Devuelve el valor del MID asignado a esta conexión.
     */
    public getMID(): number {
        return this.m_nMid;
    }

    /**
     * Asigna valor al MID de esta conexión.
     *
     * @param value Nuevo valor del MID para generar ROWIDs y demás cosillas.
     */
    public setMID(value: number): void {
        this.m_nMid = value;
    }

    /**
     * Expone o asigna valor al DBID que va a usar esta conexión para replicar
     *
     * @return Devuelve el valor del DBID asignado a esta conexión para la réplica.
     */
    public getDBID(): number {
        return this.m_nDbId;
    }

    /**
     * Asigna valor al DBID usado para generar ROWIDs y cosas por el estilo.
     *
     * @param value Nuevo valor del DBID para esta conexión.
     */
    public setDBID(value: number): void {
        this.m_nDbId = value;
    }

    /**
     * Expone o asigna valor al campo ROWID de la base de datos que se va a usar
     *
     * @return Devuelve el nombre del campo ROWID para esta conexión.
     */
    public getRowIdFieldName() {
        return this.m_strRowIdFieldName;
    }

    /**
     * Asigna valor al nombre del campo ROWID de esta conexión de datos.
     *
     * @param value Nuevo valor para el nombre del campo ROWID de la conexión.
     */
    public setRowIdFieldName(value: string): void {
        this.m_strRowIdFieldName = value;
        if (StringUtils.IsEmptyString(this.m_strRowIdFieldName)) {
            this.m_strRowIdFieldName = "ROWID";
        } else {
            this.m_nFlags |= XoneConnDataFlags.ROWIDFIELD_MODIFIED;
        }
    }

    /**
     * Expone o asigna valor al campo SQL de la base de datos que se va a usar
     *
     * @return Devuelve el nombre del campo SQL para esta conexión.
     */
    public getSqlFieldName() {
        return this.m_strSqlFieldName;
    }

    /**
     * Asigna valor al nombre del campo SQL para esta conexión.
     *
     * @param value Nombre del nuevo campo SQL para esta conexión.
     */
    public setSqlFieldName(value: string) {
        this.m_strSqlFieldName = value;
        this.m_nFlags |= XoneConnDataFlags.SQLFIELD_MODIFIED;
    }

    /**
     * Expone o asigna valor al tamaño del ROWID en esta conexión
     *
     * @return Devuelve el tamaño del campo ROWID para esta conexión.
     */
    public getRowIdLength() {
        return this.m_nRowIdLength;
    }

    /**
     * Asigna valor al tamaño del campo ROWID de esta conexión.
     *
     * @param value Nuevo tamaño del campo ROWID para esta conexión (más de 35)
     */
    public setRowIdLength(value: number) {
        this.m_nRowIdLength = value;
        // No puede ser menor que 35.. es el tamaño mínimo...
        if (this.m_nRowIdLength < 35) {
            this.m_nRowIdLength = 35;
        } else {
            this.m_nFlags |= XoneConnDataFlags.ROWIDLEN_MODIFIED;
        }
    }

    /**
     * Expone o asigna valor al tamaño del OPERID en esta conexión
     *
     * @return Devuelve el tamaño del campo OPERID para esta conexión.
     */
    public getOperIdLength() {
        return this.m_nOperIdLength;
    }

    /**
     * Asigna valor al tamaño del campo OPERID de esta conexión.
     *
     * @param value Nuevo tamaño del campo OPERID para esta conexión.
     */
    public setOperIdLength(value: number) {
        this.m_nOperIdLength = value;
        // El tamaño no puede ser menor que el mínimo (50)
        if (this.m_nOperIdLength < 50) {
            this.m_nOperIdLength = 50;
        } else {
            this.m_nFlags |= XoneConnDataFlags.OPERIDLEN_MODIFIED;
        }
    }

    /**
     * Expone la lista de cadenas de inicialización para esta conexión en concreto
     *
     * @return Devuelve una lista de cadenas de inicialización para esta conexión.
     */
    public getInitStrings() {
        return this.m_lstInitStrings;
    }

    /**
     * Expone o asigna valor al datemask de esta conexión
     *
     * @return Devuelve la máscara de fecha para esta conexión.
     */
    public getDatemask() {
        return this.m_strDatemask;
    }

    /**
     * Asigna valor a la máscara de fecha para esta conexión.
     *
     * @param value Nuevo valor para la máscara de fecha de la conexión.
     */
    public setDatemask(value: string) {
        this.m_strDatemask = value;
        if (!StringUtils.IsEmptyString(this.m_strDatemask)) {
            this.m_nFlags |= XoneConnDataFlags.DATEMASK_MODIFIED;
        }
    }

    /**
     * Expone las banderas que indican qué campos se han modificado desde el exterior.
     *
     * @return Devuelve el valor del campo de banderas de la conexión.
     */
    public getFlags() {
        return this.m_nFlags;
    }

    /**
     * Separador para encerrar nombres de tablas (apertura)
     *
     * @return Devuelve el separador de inicio para encerrar los nombres de tablas.
     */
    public getTableQuoteOpen() {
        return this.m_strTableQuoteOpen;
    }

    /**
     * Asigna valor para el separador de inicio de los nombres de tablas.
     *
     * @param value Caracter o secuencia de caracteres para iniciar los nombres de tablas.
     */
    public setTableQuoteOpen(value: string): void {
        if (!StringUtils.IsEmptyString(this.m_strTableQuoteOpen = value)) {
            this.m_nFlags |= XoneConnDataFlags.TABLEQUOTE_MODIFIED;
        }
    }

    /**
     * Separador para encerrar nombres de tablas (cierre)
     *
     * @return Devuelve el caracter o secuencia de caracteres para finalizar los nombres de tablas.
     */
    public getTableQuoteClose(): string {
        return this.m_strTableQuoteOpen;
    }

    /**
     * Asigna valor al caracter o secuencia de caracteres para finalizar los nombres de tablas.
     *
     * @param value Nuevo valor para el caracter o secuencia de caracteres de terminación de los nombres de tablas.
     */
    public setTableQuoteClose(value: string): void {
        if (!StringUtils.IsEmptyString(this.m_strTableQuoteClose = value)) {
            this.m_nFlags |= XoneConnDataFlags.TABLEQUOTE_MODIFIED;
        }
    }

    /**
     * Separador para encerrar nombres de campos (apertura)
     *
     * @return Devuelve el caracter o secuencia de caracteres para iniciar los nombres de campos.
     */
    public getFieldQuoteOpen(): string {
        return this.m_strFieldQuoteOpen;
    }

    /**
     * Asigna valor al caracter o secuencia de caracteres para iniciar los nombres de campos.
     *
     * @param value Nuevo valor para la secuencia de caracteres de inicio de los nombres de campos.
     */
    public setFieldQuoteOpen(value: string): void {
        if (!StringUtils.IsEmptyString(this.m_strFieldQuoteOpen = value)) {
            this.m_nFlags |= XoneConnDataFlags.FIELDQUOTE_MODIFIED;
        }
    }

    /**
     * Separador para encerrar nombres de campos (cierre)
     *
     * @return Devuelve el caracter o secuencia de caracteres para finalizar los nombres de campos de esta conexión.
     */
    public getFieldQuoteClose(): string {
        return this.m_strFieldQuoteClose;
    }

    /**
     * Asigna valor al caracter o secuencia de caracteres para finalizar los nombres de campos.
     *
     * @param value Nuevo valor del caracter o secuencia de caracteres usados para terminar los nombres de campo.
     */
    public setFieldQuoteClose(value: string): void {
        if (!StringUtils.IsEmptyString(this.m_strFieldQuoteClose = value)) {
            this.m_nFlags |= XoneConnDataFlags.FIELDQUOTE_MODIFIED;
        }
    }

    /**
     * TRUE si esta conexión soporta subqueries
     *
     * @return Devuelve la bandera que indica si la conexión soporta subqueries.
     */
    public getSubqueries(): boolean {
        return this.m_bSubqueries;
    }

    /**
     * Asigna valor a la bandera que indica si la conexión soporta subqueries o no.
     *
     * @param value TRUE si la conexión soporta subqueries.
     */
    public setSubqueries(value: boolean): void {
        this.m_bSubqueries = value;
        this.m_nFlags |= XoneConnDataFlags.SUBQUERIES_MODIFIED;
    }

    /**
     * Prefijo con el guión necesario para sustituir en las macros
     *
     * @return Devuelve el prefijo de las tablas con el guion ya colocado.
     */
    public getDevelopedPrefix(): string {
        // Si no hay prefijo, devolvemos una cadena vacía utilizable
        if (StringUtils.IsEmptyString(this.m_strPrefix)) {
            return "";
        }
        // De lo contrario el prefijo con el guión
        return this.m_strPrefix + "_";
    }

    /**
     * Esta función obtiene una conexión física a la fuente de datos. Reintenta tantas veces como se indique. Llama a la abstracta GetNewConnection sin parámetros.
     *
     * @param RetryCount Cantidad de veces que se reintenta en caso de que no se pueda obtener ninguna conexión.
     * @return Devuelve la conexión o NULL si no se ha podido crear ninguna.
     * @throws Exception
     */
    public GetRetryConnection(RetryCount: number, ReadOnly: boolean): IConnection {
        for (let i = 0; i < RetryCount; i++) {// Reintentar varias veces
            let connection: IConnection;
            if (null != (connection = this.GetNewConnection(ReadOnly))) {
                return connection;
            }
            // Dormir un poco para esperar
            //Thread.sleep(200);
        }// Reintentar varias veces
        // Na de na
        return null;
    }

    /**
     * Prepara una sentencia SQL sustituyendo las macros que contiene. Utiliza valores FALSE para los parámetros ChkGroup y Prefiltered.
     *
     * @param Sentence Sentencia SQL que se quiere desarrollar.
     * @return Devuelve la sentencia SQL pasada como parámetro con las macros sustituidas (incluido prefijo y demás)
     * @throws Exception
     */
    // public  PrepareSqlString(String Sentence) throws Exception {
    //     return PrepareSqlString(Sentence, false, false);
    // }

    /**
     * Prepara una sentencia SQL sustituyendo las macros que contiene.
     * F13012403: Poner PrepareSqlString a nivel de clase base para que lo usen los hijos.
     * Ahora esto no sería abstracto, sino virtual y tendría implementación propia y común para todos los que heredan
     * de esta clase.
     *
     * @param Sentence    Sentencia SQL o cadena que se quiere desarrollar.
     * @param ChkGroup    TRUE para efectuar verificación de GROUP BY para sustituir WHERE por HAVING.
     * @param Prefiltered TRUE si la sentencia viene prefiltrada, en cuyo caso no se sustituye WHERE por HAVING.
     * @return Devuelve la sentencia original con el operador sustituido.
     * @throws Exception
     */
    public PrepareSqlString(Sentence: string, ChkGroup?: boolean, Prefiltered?: boolean) {
        let mn: IXoneObject;
        let ent: IXoneObject;
        let usr: IXoneObject;
        let str;
        let strSQL = Sentence;
        if (strSQL.contains("##")) {// Algo habrá
            // Buscar la empresa activa...
            ent = this.m_owner.getCompany();
            if (strSQL.contains("##ENTID##")) // Tiene la macro de empresa
            {// Empresa
                if (ent != null) {
                    strSQL = StringUtils.Replace(strSQL, "##ENTID##", ent.GetObjectIdString());
                } else {
                    strSQL = StringUtils.Replace(strSQL, "##ENTID##", "NULL");
                }
            }// Empresa
            if (strSQL.contains("##MID##")) {// MID solo si está replicando
                if (this.m_bIsReplicating) {// Está replicando
                    str = "'" + this.m_nMid + "'";
                    strSQL = StringUtils.Replace(strSQL, "##MID##", str);
                }// Está replicando
            }// MID solo si está replicando
            if (strSQL.contains("##ENTIDCOLL##")) {// Colección de empresas
                str = this.m_owner.getEntIdColl();
                //
                // Como los valores pueden venir de fuera, solamente consideraría
                // valor único si
                // además de no tener comas no tiene espacios.
                if (StringUtils.IsEmptyString(str)) {
                    str = "NULL";
                }
                if (!str.contains(",") && !str.contains(" ")) {
                    strSQL = StringUtils.Replace(strSQL, "=##ENTIDCOLL##", "="
                        + str);
                } else {
                    strSQL = StringUtils.Replace(strSQL, "=##ENTIDCOLL##",
                        " IN (" + str + ")");
                }
            }// Colección de empresas
            if (strSQL.contains("##ENTIDLEVEL##")) {// Colección de
                // empresas
                str = this.m_owner.getEntIdLevel();
                if (StringUtils.IsEmptyString(str)) {
                    str = "NULL";
                }
                //
                // Con este continúa
                // Como los valores pueden venir de fuera, solamente consideraría
                // valor único si
                // además de no tener comas no tiene espacios.
                if (!str.contains(",") && !str.contains(" ")) {
                    strSQL = StringUtils.Replace(strSQL, "=##ENTIDLEVEL##", "=" + str);
                } else {
                    strSQL = StringUtils.Replace(strSQL, "=##ENTIDLEVEL##", " IN (" + str + ")");
                }
            }// Colección de empresas
            if (strSQL.contains("##ENTIDOWNER##")) {// ID de la empresa propietaria
                str = this.m_owner.getEntIdOwner();
                if (StringUtils.IsEmptyString(str)) {
                    str = "NULL";
                }
                strSQL = StringUtils.Replace(strSQL, "=##ENTIDOWNER##", "=" + str);
            }// ID de la empresa propietaria
            if (strSQL.contains("##CURRID##")) {// Moneda actual
                if (null != (mn = this.m_owner.getCurrency())) {
                    strSQL = StringUtils.Replace(strSQL, "##CURRID##", mn.GetObjectIdString());
                } else {
                    strSQL = StringUtils.Replace(strSQL, "##CURRID##", "NULL");
                }
            }// Moneda actual
            if (strSQL.contains("##USERID##")) {// ID de usuario
                if (null != (usr = this.m_owner.getUser())) {
                    strSQL = StringUtils.Replace(strSQL, "##USERID##", usr.GetObjectIdString());
                } else {
                    strSQL = StringUtils.Replace(strSQL, "##USERID##", "NULL");
                }
            }// ID de usuario
            if (strSQL.contains("##USERIDCOLL##")) {// Conjunto de usuarios
                str = this.m_owner.getUserIdColl();
                if (StringUtils.IsEmptyString(str)) {
                    strSQL = StringUtils.Replace(strSQL, "=##USERIDCOLL##", " IS NOT NULL");
                } else {// Reemplazar por la cadenilla correcta
                    if (str.contains(",")) {
                        str = "=" + str;
                    } else {
                        str = " IN (" + str + ")";
                    }
                    strSQL = StringUtils.Replace(strSQL, "=##USERIDCOLL##", str);
                }// Reemplazar por la cadenilla correcta
            }// Conjunto de usuarios
        }// Algo habrá
        // A12050301: Adicionar una macro en la conexión para generar un nuevo ROWID.
        // Macro para ROWID.
        if (strSQL.contains("##ROWID##")) {// Tiene ROWID
            strSQL = strSQL.replace("##ROWID##", this.GenerateRowId(Utils.EMPTY_STRING));
        }// Tiene ROWID
        // Sustituir el prefijo
        if (strSQL.contains("##PREF##")) {
            strSQL = StringUtils.Replace(strSQL, "##PREF##", this.getDevelopedPrefix());
        }
        // Sustituir las comillas
        if (strSQL.contains("##QUOTES##")) {
            strSQL = StringUtils.Replace(strSQL, "##QUOTES##", "\"");
        }
        // Sustituir macros de fecha en el SQL si es que vienen
        // El Now
        if (strSQL.contains("##")) {// Si quedan cosas
            let dt = new Calendar();
            // O11011201:	Optimizar un poco PrepareSqlString.
            // Optimizar un poco esto
            if (strSQL.contains("##NOW_TIME##")) {// NOW_TIME
                str = this.DevelopObjectValue(dt);
                strSQL = StringUtils.Replace(strSQL, "##NOW_TIME##", str);
            }// NOW_TIME
            if (strSQL.contains("##DAY##")) {// DAY
                str = String.format("%d", dt.get(Calendar.DAY_OF_MONTH));
                strSQL = StringUtils.Replace(strSQL, "##DAY##", str);
            }// DAY
            if (strSQL.contains("##MONTH##")) {// MONTH
                str = String.format("%d", dt.get(Calendar.MONTH));
                strSQL = StringUtils.Replace(strSQL, "##MONTH##", str);
            }// MONTH
            if (strSQL.contains("##YEAR##")) {// YEAR
                str = String.format("%d", dt.get(Calendar.YEAR));
                strSQL = StringUtils.Replace(strSQL, "##YEAR##", str);
            }// YEAR
            if (strSQL.contains("##NOW##")) {// NOW
                ObjUtils.ZeroCalendarTime(dt);
                str = this.DevelopObjectValue(dt);
                // O11011201:	Optimizar un poco PrepareSqlString.
                // Aquí hay replace, así que usarlo
                str = str.replace("00:00:00", "");////StringUtils.removeChars(str, "00:00:00");
                ////str = StringUtils.removeChars(str, " ");
                strSQL = StringUtils.Replace(strSQL, "##NOW##", str);
            }// NOW
            if (strSQL.contains("##NBSP##")) {
                strSQL = StringUtils.Replace(strSQL, "##NBSP##", " ");
            }
        }// Si quedan cosas
        // Puede que tenga que chequear si usa GROUP BY y no hay subselects
        // Agregar un chequeo si se trata de una cadenilla que viene prefiltrada
        /*
         * F12060703: Quitar sustituciones a nivel de cadena de filtrado en sqls.
         * Esto no
        if (!m_bSubqueries && ChkGroup && !Prefiltered)
        {// Buscar dentro del SQL
            str = strSQL.toLowerCase();
            // K10052601:	Modificación para sustituir WHERE por HAVING en los sql.
            if (str.indexOf("group by ") !=-1 && str.indexOf("where ") !=-1) 
            {// Cambiar el WHERE por HAVING
                int wherePos =  str.indexOf("where ");
                int groupPos =  str.indexOf("group by ");
                if (wherePos > groupPos)
                    strSQL = StringUtils.Replace(strSQL, " WHERE ", " HAVING ");
                else 
                {
                    String whereClause = strSQL.substring(wherePos, groupPos);
                    String groupClause = strSQL.substring(groupPos);
                    strSQL = strSQL.substring(0, wherePos);
                    strSQL = strSQL + " " + groupClause +  " " + whereClause;
                    strSQL = StringUtils.Replace(strSQL, " WHERE ", " HAVING ");
                }
            }// Cambiar el WHERE por HAVING
        }// Buscar dentro del SQL
        */
        return strSQL;
    }

    /**
     * Dado un nombre de tabla u otro tipo de objeto de base de datos, lo devuelve formateado con su prefijo si este está definido.
     *
     * @param ObjectName Nombre de tabla, query, vista, etc, cuyo nombre se quiere formatear sustituyendo el prefijo, introduciendo los caracteres de inicio y fin, etc.
     * @param Prefix     Prefijo opcional para usar en la sustitución. NULL para usar el que tenga la conexión.
     * @return Devuelve el nombre del objeto ya desarrollado.
     */
    public FixObjectName(ObjectName: string, Prefix: string = null): string {
        let str = ObjectName, str1: string;
        let strPrefix: string;

        // Esto solo hay que hacerlo si tiene valor
        if (StringUtils.IsEmptyString(str)) {
            return str;
        }
        strPrefix = this.m_strPrefix;
        // Si trae prefijo de fuera, sobrepasa al calculado dentro
        if (!StringUtils.IsEmptyString(Prefix)) {
            strPrefix = Prefix;
        }
        // Ahora sí se puede
        if (!StringUtils.IsEmptyString(str)) {// Solo si tiene nombre
            // Si tiene prefijo y no es la macro de anulación
            if (!StringUtils.IsEmptyString(strPrefix) && !strPrefix.equals("##NONE##")) {// Tiene prefijo
                if (str.charAt(0) != '.') {
                    str = strPrefix + "_" + str;
                }
            }// Tiene prefijo
        }// Solo si tiene nombre
        // Si lleva comillas ponérselas por qué no
        if (!StringUtils.IsEmptyString(this.m_strTableQuoteOpen)) {// Montar los separadores
            str1 = String.format("%s%s%s", this.m_strTableQuoteOpen, str, this.m_strTableQuoteClose);
            return str1;
        }// Montar los separadores
        // Devolver tal cual
        return str;
    }

    /**
     * ADD TAG Implemetar Develop Object en la clase base
     * Desarrolla el valor de un objeto y lo devuelve como cadena lista para insertar en una sentencia SQL o un filtro.
     * <p>
     * K12102201: Modificación de DevelopObjectValue para pasar ForceNulls como parámetro.
     * Lo que redefinimos es la función con parámetro porque el resto la verdad es que no nos interesa :-P
     *
     * @param Value Valor que se quiere desarrollar. Se utilizará la máscara de fecha de la conexión.
     */
    // public DevelopObjectValue(Value:any, ForceNulls:boolean=true):string {
    //     return this.m_developer.DevelopObjectValue(Value, this.m_strDatemask, ForceNulls);
    // }

    public DevelopObjectValue(Value: any, format?: string | boolean, ForceNulls?: boolean): string {
        if (format == null)
            return this.m_developer.DevelopObjectValue(Value, this.m_strDatemask, true);
        if (typeof format === 'boolean')
            return this.m_developer.DevelopObjectValue(Value, this.m_strDatemask, format);
        return this.m_developer.DevelopObjectValue(Value, format, ForceNulls);
    }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Ejecuta una sentencia SQL parseada.
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado.
     *
     * @param Sentence
     * @return
     * @throws Exception
     */
    public ExecuteLocalParsedSql(Sentence: any): any {
        return this.ExecuteParsedSql(null, Sentence, false);
    }


    /**
     * A14011501: Optimizaciones para obtener claves numéricas de conexiones sqlite y demás.
     * Devuelve la cantidad de registros que afectó la última operación de modificación de datos
     * si es que tenemos este valor, de lo contrario (por defecto) devuelve -1
     *
     * @param TableName
     * @return
     */
    public LastAffectedRecords(TableName: string): number {
        return -1;
    }

    /**
     * A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
     * A nivel de esta estructura se soportan transacciones.. o no
     *
     * @return Devuelve un objeto indicando el resultado de la transacción... o NULL
     * @throws Exception
     */
    public commit(): any {
        return null;
    }

    /**
     * A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
     * Soportar transacciones a este nivel también.
     *
     * @throws Exception
     */
    public beginTrans(): void {
    }

    /**
     * A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
     * Soportar transacciones también a este nivel.
     *
     * @throws Exception
     */
    public rollBack(): void {
    }

    /**
     * A11111101: Soportar plataformas a nivel de nodos de conexión.
     */
    public SetPlatform(Platform: string): void {
        this.m_strPlatform = Platform;
    }

    //A11111101: Soportar plataformas a nivel de nodos de conexión.
    //Devuelve un valor de atributo del nodo teniendo en cuenta el platform.
    protected GetNodeValue(Key: string): string {
        let strValue;

        if (this.m_xmlNodeData == null) {
            return null;
        }
        if (this.m_platform != null) {// Tiene plataforma
            if (!StringUtils.IsEmptyString(strValue = this.m_platform.getAttrValue(Key))) {
                return strValue;
            }
        }// Tiene plataforma
        // De lo contrario lo sacamos del nodo directamente
        return this.m_xmlNodeData.getAttrValue(Key);
    }


    /**
     * TODO ADD TAG
     * Cancela los procesos asincrónicos o no asincrónicos que haya corriendo en este momento. Quienes
     * estén esperando por algo recibirán códigos de error o timeouts, pero la estructura volverá a estar
     * disponible para efectuar llamadas.
     * <p>
     * La clase base no hace nada.
     *
     * @param flags Dependiendo de la implementación aquí vendrán cositas para parametrizar la cancelación.
     * @return
     */
    public cancelProcesses(flags: number): number {
        return 0;
    }


    /**
     * Aqui vamos a abstraer el mecanismo de autenticar en las conexiones online
     * para poder tener un token de session. por ahora lo hacemos por la via sencilla
     * de mandar un metod Auth para arriba pero podemos complicarlo para Auth2 o algo asi
     */
    public getTokenFromAuth(login: string, pass: string): string {
        return Utils.EMPTY_STRING;
    }



    //@ScriptAllowed
    // TAG 20050206: Luis: para la depuracion el valoor por defecto
    public toString() {
        return "Connection: " + this.m_strName;
    }

    // // TAG 20050205: Luis: exponer metodos script en esta clase
    // private void addJavascriptFunctions() {
    //     if (lstScriptAllowedMethods.size() <= 0) {
    //         return;
    //     }
    //     for (final Method method : lstScriptAllowedMethods) {
    //         ScriptableObject.putProperty(this, method.getName(), new BaseFunctionJavaMethodWrapper(this, method));
    //     }
    // }

    /**
     * Genera un ROWID para la conexión actual. Emplea los criterios de número de tabla, MID, DBID, etc.
     *
     * @param TableName Nombre de la tabla para la cual se quiere generar el ROWID.
     * @return Devuelve un ROWID respetando los valores de réplica y el tamaño del campo ROWID.
     */
    public GenerateRowId(TableName?: string): string { return Guid.create().toString(); }

    /**
     * TRUE si el DBMS soporta la sintaxis TOP x
     *
     * @return Esta función posiblemente no se redefina en esta plataforma.
     */
    public abstract TopAllowed(): boolean;

    /**
     * TRUE si el DBMS soporta la sintaxis LIMIT x
     *
     * @return Mirar arriba plis.
     */
    public abstract LimitAllowed(): boolean;

    /**
     * TRUE si la conexión tiene caracteres para escapear cadenas de caracteres.
     *
     * @return Devuelve TRUE si hay caracteres en la lista para sustituir.
     */
    public abstract HasEscapeChars(): boolean;

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Devuelve TRUE si la conexión acepta sentencias SQL parseadas para su ejecución o para queries.
     *
     * @return
     */
    public abstract acceptsParsedSentences(): boolean;

    public abstract acceptsEmptyQueries(): boolean;

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Devuelve TRUE si la conexión permite recuperar un ID autonumérico dado un ROWID.
     *
     * @return
     */
    public abstract retrievesAutonumericKeys(): boolean;


    /**
     * Obtiene una conexión física a la fuente de datos que encapsula esta clase. Es de obligatoria redefinición por los derivados de esta clase.
     *
     * @return Devuelve una nueva conexión de datos (o la misma si solo se emplea una)
     * @throws Exception
     */
    public abstract GetNewConnection(ReadOnly: boolean): IConnection;


    /**
     * Crea un conjunto de datos sin conexión original. Para ello crea una local y la devuelve como
     * parte del conjunto que la cierra cuando lo cierran a él
     *
     * @param Sentence Sentencia SQL que se ejecuta para obtener el conjunto de datos.
     * @return Devuelve un conjunto de datos o NULL si algo falla.
     * @throws Exception
     */
    /// public CreateRecordset(Sentence:string): IResultSet {
    //     return this.CreateRecordset(null, Sentence);
    // }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Ejecuta una sentencia SQL parseada que devuelve datos.
     *
     * @param Sentence
     * @return
     * @throws Exception
     */
    /// public CreateRecordset(Sentence:any): IResultSet{
    //     return this.CreateRecordset(null, Sentence);
    // }

    /**
     * Crea un objeto de acceso a datos (recordset) para leer los datos de un objeto
     *
     * @param Connection Conexión que se quiere usar para abrir la fuente de datos. NULL para crear una local y usarla solamente para esta función.
     * @param Sentence   Setencia SQL (SELECT) que se quiere ejecutar para abrir la fuente de datos.
     * @param MaxRows    Máximo de registros para no usar el que tiene por defecto la clase.
     * @return Devuelve el conjunto de datos creado o NULL si algo falla.
     * @throws Exception
     */
    /// public abstract CreateRecordset(Connection Connection, String Sentence, int MaxRows): IResultSet;

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Esta implementación permite la ejecución de sentencias SQL parseadas con anterioridad.
     *
     * @param Connection
     * @param Sentence
     * @param MaxRows
     * @return
     * @throws Exception
     */
    /// public abstract ResultSet CreateRecordset(Connection: IConnection | string, ...args?:any) throws Exception;

    /*
     * TODO ADD TAG 17042014: Luis Un recordset a partir de la clase de parametros
     */
    public abstract CreateRecordset(Connection: IConnection | string, ...args: any): IResultSet;
    /*
     * TODO ADD TAG 17042014: Luis Un recordset a partir de la clase de parametros
     */
    public abstract CreateRecordsetAsync(Connection: IConnection | string, ...args: any): Promise<IResultSet>;
    // Para contar los records
    public abstract CountAsync(Connection: IConnection | string, ...args: any): Promise<IResultSet>;
    /**
     * Crea un objeto de acceso a datos (recordset) para leer los datos de un objeto
     *
     * @param Connection Conexión que se quiere usar para abrir la fuente de datos. NULL para crear una local y usarla solamente para esta función.
     * @param Sentence   Setencia SQL (SELECT) que se quiere ejecutar para abrir la fuente de datos.
     * @return Devuelve el conjunto de datos creado o NULL si algo falla.
     * @throws Exception
     */
    //public abstract ResultSet CreateRecordset(Connection Connection, String Sentence) throws Exception;

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Ejecuta una sentencia SQL parseada que devuelve datos.
     *
     * @param conn
     * @param Sentence
     * @return
     * @throws Exception
     */
    /// public abstract ResultSet CreateRecordset(Connection conn, SqlParser Sentence) throws Exception;

    /**
     * Ejecuta una sentencia SQL dentro de la conexión que manipula este objeto. No lleva conexión física como parámetro porque crea una temporal, trabaja con ella y la cierra.
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado.
     *
     * @param Sentence Sentencia SQL que se quiere ejecutar.
     * @return Devuelve TRUE si la ejecución de la sentencia SQL es correcta.
     * @throws Exception
     */
    // public ExecuteSqlString(Sentence: string): any {
    //     return ExecuteSqlString(null, Sentence, true);
    // }

    /**
     * A10090601:	Incluir el mecanismo de réplica selectiva por colecciones a la maquinaria.
     * Ejecuta un SQL sin replicar
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado.
     */
    // public Object ExecuteLocalSqlString(String Sentence) throws Exception {
    //     return ExecuteSqlString(null, Sentence, false);
    // }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Ejecuta una sentencia SQL parseada con réplica.
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado.
     *
     * @param Sentence
     * @return
     * @throws Exception
     */
    // public Object ExecuteParsedSql(SqlParser Sentence) throws Exception {
    //     return ExecuteParsedSql(null, Sentence, true);
    // }

    /**
     * Ejecuta una sentencia SQL dentro de la conexión que manipula este objeto.
     * A10090601:	Incluir el mecanismo de réplica selectiva por colecciones a la maquinaria.
     * Un parámetro para decir si replica.
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado
     *
     * @param Connection Conexión de datos que se quiere usar para ejecutar la sentencia. NULL para crear una temporal, ejecutar la sentencia y cerrarla.
     * @param Sentence   Sentencia SQL que se va a ejecutar.
     * @param Replicate  TRUE si replica.
     * @return Devuelve TRUE si la sentencia se ejecuta correctamente.
     * @throws SQLException
     * @throws Exception
     */
    public abstract ExecuteSqlString(Connection: IConnection | string, Sentence?: SqlParser | string, Replicate?: boolean, ...args): any;
    public abstract ExecuteSqlStringAsync(Connection: IConnection | string, Sentence?: SqlParser | string, Replicate?: boolean, ...args): Promise<any>;

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Esta implementación opcional permite ejecutar una sentencia SQL parseada en vez de una cadena SQL.
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado.
     *
     * @param Connection
     * @param Sentence
     * @param Replicate
     * @return
     * @throws SQLException
     * @throws Exception
     */
    public abstract ExecuteParsedSql(Connection: IConnection | string, Sentence?: SqlParser | string, Replicate?: boolean, ...args): any;
    public abstract ExecuteParsedSqlAsync(Connection: IConnection | string, Sentence?: SqlParser | string, Replicate?: boolean, ...args): Promise<any>;



    /**
     * Reemplaza el valor del operador específico dentro de la cadena SQL que se pasa como parámetro.
     *
     * @param Collection Colección con la que se está trabajando.
     * @param Sentence   Sentencia SQL o cadena en la cual se quiere sustituir el operador.
     * @param OperName   Nombre del operador que se quiere sustituir.
     * @return Devuelve la sentencia original con el operador sustituido.
     * @throws Exception
     */
    public abstract ReplaceCustomOper(Collection: IFieldProperties, Sentence: string, OperName: string): string;

    /**
     * Inserta una sentencia TOP dentro del SQL que se pasa como parámetro. Colocada aquí para mantener compatibilidad de interfaces
     * pero no se implementará en esta plataforma.
     *
     * @param Sentence Sentencia SQL que se quiere modificar.
     * @param Rows     Cantidad de filas que se quiere limitar en el TOP x o LIMIT x
     * @return La misma sentencia pasada como parámetro.
     */
    public abstract InsertTop(Sentence: string, Rows: number): string;

    /**
     * Dado un nombre de campo lo formatea usando los caracteres de inicio y fin de campo.
     *
     * @param FieldName Nombre del campo a formatear.
     * @return Devuelve el nombre del campo encerrado en sus secuencias de inicio y fin de campo.
     */
    public abstract QuoteFieldName(FieldName: string): string;

    /**
     * Dado un nombre de tabla u otro tipo de objeto de base de datos, lo devuelve formateado con su prefijo si este está definido.
     *
     * @param ObjectName Nombre de tabla u objeto en general de la base de datos para arreglar con el prefijo.
     * @return Devuelve el nombre de objeto ya desarrollado con su prefijo si aplica.
     */
    // public String FixObjectName(String ObjectName):string {
    //     return FixObjectName(ObjectName, null);
    // }



    /**
     * Dada una cadena sustituye los caracteres para los cuales se hayan definido secuencias de escape por dichas secuencias.
     *
     * @param Value Cadena en la cual se van a sustituir los caracteres por secuencias de escape.
     * @return Devuelve la cadena original con los caracteres sustituidos por sus secuencias de escape.
     */
    public abstract EscapeString(Value: string): string;

    /**
     * Desarrolla el valor de un objeto y lo devuelve como cadena lista para insertar en un SQL o un filtro
     *
     * @param Value Valor que se quiere desarrollar. Se usará la máscara de fecha de la conexión.
     * @return CAdena representando el valor del objeto para escribir en una sentencia SQL o un filtro de datos.
     */
    // public DevelopObjectValue(Value:any):string {
    //     return DevelopObjectValue(Value, true);
    // }

    /**
     * Esta función se emplea para cerrar conexiones locales si es que las tiene. No necesariamente tiene por qué
     * usarse para terminar la clase, simplemente cuando se quiera cerrar o destruir los objetos locales.
     */
    public abstract Terminate(): void;

    /**
     * Si el DBMS soporta left outer joins
     */
    public abstract IsOuterJoinSupported(): boolean;

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Devuelve una clave numérica dada una tabla y un ROWID. Permite abstraer la obtención de la clave autonumérica
     * en conexiones que no son offline.
     *
     * @param TableName
     * @param RowIdFieldName
     * @return
     * @throws Exception
     */
    public abstract RetrieveNumericKey(TableName: string, KeyName: string, RowIdFieldName: string, RowId: string): number;

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Esta función permite calcular si un ROWID es único dentro de la conexión de datos.
     *
     * @param tableName
     * @param RowID
     * @return
     * @throws Exception
     */
    public abstract isUniqueRowID(tableName: string, RowID: string): boolean;

    // A11120701: Mecanismo para exponer el DBMS de una conexión.
    // Expone el tipo de DBMS que tiene debajo esta conexión. Obligatorio implementar.
    public abstract getDbmsTag(): string;

}