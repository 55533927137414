<template>
	<!-- is anchor|link? -> <a href></a> -->
	<a v-if="(attributes.viewMode === 'link' || attributes.viewMode === 'anchor') && attributes.href" :href="href" target="_blank"><slot></slot></a>
	<!-- else -->
	<slot v-else></slot>
</template>

<script>
import { onMounted, ref, watchEffect } from "@vue/runtime-core";
export default {
	name: "Column",
	props: {
		attributes: { type: Object, required: true },
		xoneDataObject: { type: Object, required: true },
	},
	setup(props) {
		const href = ref();
		onMounted(() => {
			if ((props.attributes.viewMode !== "link" && props.attributes.viewMode !== "anchor") || !props.attributes.href) return;
			href.value = props.attributes.href;
			Object.keys(props.xoneDataObject.model).forEach((attrKey) => {
				if (href.value === `##FLD_${attrKey}##`) {
					watchEffect(() => (href.value = props.xoneDataObject.model[attrKey]));
				}
			});
		});

		return { href };
	},
};
</script>

<style scoped>
a {
	text-decoration: none;
}

a:hover {
	cursor: pointer;
}
</style>
