<template>
  <div v-if="isDesigner" class="xone-designer-over-container">
    <div
      class="xone-designer-over"
      @click="onMouseClick()"
      @mouseleave="onMouseLeave()"
      v-if="isDesigner && location"
      :style="{
        top: location.top,
        left: location.left,
        width: location.width,
        height: location.height,
        '--border': border,
      }"
    ></div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import dragAndDrop from "../../composables/DragAndDrop";

export default {
  setup() {
    const overControl = dragAndDrop.getOverControl();
    const selectedControl = dragAndDrop.getSelectedControl();
    const location = ref();

    watch(
      () => overControl.value,
      (element) => {
        try {
          if (element !== overControl.value) return;

          if (!element || element === selectedControl.vue)
            return (location.value = null);
          location.value = {
            top: element.getBoundingClientRect().top + "px",
            left: element.getBoundingClientRect().left + "px",
            width: element.getBoundingClientRect().width + "px",
            height: element.getBoundingClientRect().height + "px",
          };
        } catch {
          location.value = null;
        }
      }
    );
    return {
      location,
      isDesigner: dragAndDrop.getIsDesigner(),
      border: dragAndDrop.getBorderOver(),
      onMouseClick: () => {
        dragAndDrop.setSelectedControl(overControl.value);
      },
      onMouseLeave: () => {
        dragAndDrop.setOverControl(null);
      },
    };
  },
};
</script>

<style scoped>
.xone-designer-over-container {
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}
.xone-designer-over {
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 30px;
  background-color: transparent;
  outline: var(--border);
  z-index: 9999;
  max-height: 100vh;
  pointer-events: none;
}

button {
  pointer-events: none;
  cursor: pointer;
}
</style>