<template>
	<div
		v-show="!isDisableVisible"
		class="xone-prop"
		ref="propElement"
		:xone-name="attributes.name"
		:style="{
			// set variables
			'--label-height':
				(controlHeight && `${/* convierto a entero para que las líneas las renderice bien */ Math.ceil(controlHeight)}px`) ||
				`calc(${attributes.lines} + 11px)` ||
				'100%',
			'--text-weight': attributes.fontBold ? 'bold' : 'normal',
			'--input-bgcolor':
				((attributes.locked || isDisableEdit) && attributes.textBgColorDisabled && attributes.textBgColorDisabled) ||
				attributes.textBgColor ||
				attributes.bgColor ||
				'transparent',
			'--input-forecolor':
				((attributes.locked || isDisableEdit) && attributes.textForeColorDisabled && attributes.textForeColorDisabled) ||
				(attributes.textForeColor && attributes.textForeColor) ||
				attributes.foreColor,
			'--padding-top': `${paddings.top ?? 0}px`,
			'--padding-right': `${paddings.right ?? 0}px`,
			'--padding-bottom': `${paddings.bottom ?? 0}px`,
			'--padding-left': `${paddings.left ?? 0}px`,
			'--margin-top': `${margins.top ?? 0}px`,
			'--margin-right': `${margins.right ?? 0}px`,
			'--margin-bottom': `${margins.bottom ?? 0}px`,
			'--margin-left': `${margins.left ?? 0}px`,
			'--border-color': attributes.borderColor,
			'--border-width': attributes.borderWidth,
			'--elevation': `${attributes.elevation}px`,
			// Size
			height: (controlHeight && `${controlHeight}px`) || (finalImgSize && `${finalImgSize.height}px`) || (attributes.height ?? 'auto'),
			width: (controlWidth && `${controlWidth}px`) || (finalImgSize && `${finalImgSize.width}px`),
			minHeight: attributes.minHeight ? attributes.minHeight : null,
			minWidth: attributes.minWidth ? attributes.minWidth : null,
			// Expanded
			flexGrow: attributes.width === 'grow' ? 1 : null,
			// Background
			backgroundColor: displayScreenThresholds && '#ff000025',
			position: attributes.floating.floating && 'absolute',
			zIndex: attributes.floating.floating && '9',
			top: attributes.floating.floating && floatingTop && floatingTop,
			left: attributes.floating.floating && floatingLeft && floatingLeft,
			// Border
			border: displayScreenThresholds && '1px solid #ff0000',
			// Designer mode
			cursor: isDesigner && isDragging && 'move',
			// Overflow
			overflow: isContents || isImage ? 'hidden' : null,
			alignItems: inputType === 'checkbox' ? 'center' : null,
		}"
		@mousedown="isDesigner && onMouseDown($event)"
		@mouseenter="isDesigner && onMouseEnter($event)"
		@mouseleave="isDesigner && onMouseLeave($event)"
		@mousemove="isDesigner && onDragOver($event)"
		@touchmove="isDesigner && onDragOver($event)"
	>
		<!-- Input Prop -->
		<InputProp
			v-if="isInput"
			:xoneDataObject="xoneDataObject"
			:attributes="attributes"
			:controlWidth="controlWidth"
			:controlHeight="controlHeight"
			:inputType="inputType"
			:isDisableEdit="isDisableEdit"
			:checkboxSize="checkboxSize"
		></InputProp>
		<!-- Button -->
		<ButtonProp
			v-if="isButton"
			:xoneDataObject="xoneDataObject"
			:attributes="attributes"
			:paddings="paddings"
			:isDisableEdit="isDisableEdit"
			:containerWidth="containerWidth"
			:containerHeight="containerHeight"
		></ButtonProp>
		<!-- Img / Photo / Draw -->
		<ImageProp
			v-if="isImage"
			:xoneDataObject="xoneDataObject"
			:attributes="attributes"
			:controlWidth="controlWidth"
			:controlHeight="controlHeight"
			:containerWidth="containerWidth"
			:containerHeight="containerHeight"
		></ImageProp>
		<!-- Contents -->
		<Contents
			v-if="isContents"
			:xoneDataObject="xoneDataObject"
			:attributes="attributes"
			:controlWidth="controlWidth || containerWidth"
			:controlHeight="controlHeight || containerHeight"
			:isDisableEdit="isDisableEdit"
		></Contents>
		<!-- Web -->
		<WebProp
			v-if="isWeb"
			:xoneDataObject="xoneDataObject"
			:attributes="attributes"
			:controlWidth="controlWidth"
			:controlHeight="controlHeight"
		></WebProp>
		<!-- Video -->
		<VideoProp
			v-if="isVideo"
			:xoneDataObject="xoneDataObject"
			:attributes="attributes"
			:controlWidth="controlWidth"
			:controlHeight="controlHeight"
		></VideoProp>
		<Breadcrumb v-if="isBreadcrumb" :attributes="attributes"></Breadcrumb>
	</div>
</template>

<script>
// vue
import { computed, inject, ref, Ref, ComputedRef, onMounted, watch, nextTick } from "vue";
// components
import ButtonProp from "./propComponents/Button.vue";
import InputProp from "./propComponents/Input.vue";
import ImageProp from "./propComponents/Image.vue";
import Contents from "./propComponents/Contents.vue";
import WebProp from "./propComponents/Web.vue";
import VideoProp from "./propComponents/Video.vue";
import Breadcrumb from "./propComponents/Breadcrumb.vue";
// composables
import { xoneAttributesHandler, PropAttributes, Margins, Paddings } from "../composables/XoneAttributesHandler";
import dragAndDrop from "../composables/DragAndDrop";
import { XoneDataObject } from "../composables/appData/core/XoneDataObject";
import { getImagePathAndSize } from "../composables/helperFunctions/ImageHelper";

export default {
	name: "Prop",
	props: {
		/** xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 */
		xoneDataObject: { type: Object, required: true },
		control: { type: Object, default: undefined, required: true },
		containerWidth: { type: Number, default: 0 },
		containerHeight: { type: Number, default: 0 },
	},
	components: {
		ButtonProp,
		InputProp,
		ImageProp,
		Contents,
		WebProp,
		VideoProp,
		Breadcrumb,
	},
	setup(props) {
		/**
		 * @type {Ref<HTMLDivElement>}
		 */
		const propElement = ref();

		const displayScreenThresholds = inject("displayScreenThresholds");

		/**
		 * Attributes model
		 * @type {ComputedRef<PropAttributes>}
		 */
		const attributesModel = computed(() => xoneAttributesHandler.getPropAttributes(props.control.attributes));

		/**
		 * Reactive attributes
		 * @type {Ref<PropAttributes>}
		 */
		const attributes = ref({ ...attributesModel.value });

		// Observe changes in attributes model and data model with ##FLD_ fields and fetch them to attributes
		xoneAttributesHandler.watchAttributes(attributes, attributesModel, props.xoneDataObject.model);

		/**
		 * Check if disablevisible
		 * @type {ComputedRef<boolean>}
		 */
		const isDisableVisible = computed(() => {
			if ((attributes.value.disableVisible ?? "") === "") return false;
			return xoneAttributesHandler.evalFormula(attributes.value.disableVisible, props.xoneDataObject?.model);
		});

		/**
		 * Image size
		 * @type {Ref<number|null>}
		 */
		let imgSize = ref(null);

		const calculateImgSize = async () => {
			if (!attributes.value.image) return;
			const imgPathSize = await getImagePathAndSize(attributes.value.image, "icons");
			if (!imgPathSize) return;
			imgSize.value = {
				width: xoneAttributesHandler.getScaledSize(imgPathSize.imgSize.width + "px", props.containerWidth, widthFactor.value),
				height: xoneAttributesHandler.getScaledSize(imgPathSize.imgSize.height + "px", props.containerHeight, heightFactor.value),
			};
		};

		// Recalculate image size if attributes.image change
		onMounted(() => calculateImgSize());

		watch(
			() => props.containerHeight,
			() => calculateImgSize()
		);

		const finalImgSize = computed(() => {
			let width = controlWidth.value;
			if (!width && imgSize.value) {
				if (controlHeight.value) width = (controlHeight.value * imgSize.value.width) / imgSize.value.height;
				else width = imgSize.value.width;
			}

			let height = controlHeight.value;
			if (!height && imgSize.value) {
				if (controlWidth.value) height = (controlWidth.value * imgSize.value.height) / imgSize.value.width;
				else height = imgSize.value.height;
			}

			return { width, height };
		});

		/**
		 * Input type depending on prop type
		 * @type {ComputedRef<string>}
		 */
		const inputType = computed(() => {
			if (
				attributes.value.viewMode === "breadcrumbview" ||
				attributes.value.viewMode === "breadcrumbsview" ||
				attributes.value.viewMode === "breadcumbview" ||
				attributes.value.viewMode === "routerview"
			)
				return null;
			switch (attributes.value.type) {
				case "T":
					return "text";
				case "X":
					return "password";
				case "N":
					if (attributes.value.viewMode === "slider") return "range";
					return "number";
				case "NC":
					return "checkbox";
				case "D":
					return "date";
				case "TT":
					return "time";
				case "DT":
					return "datetime-local";
				case "AT":
					return "text";
				case "TL":
					return "text";
				default:
					if (attributes.value.type.length != 0 && attributes.value.type[0] === "N") return "number";
					return null;
			}
		});

		/**
		 * Scale Factor
		 * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
		 */
		const { widthFactor, heightFactor } = inject("scaleFactor");

		/**
		 * Calculate width of the current prop
		 * @type {ComputedRef<number>}
		 */
		const controlWidth = computed(() => xoneAttributesHandler.getScaledSize(attributes.value.width, props.containerWidth, widthFactor.value));

		/**
		 * Calculate height of the current prop
		 * @type {ComputedRef<number>}
		 */
		const controlHeight = computed(() => xoneAttributesHandler.getScaledSize(attributes.value.height, props.containerHeight, heightFactor.value));

		/**
		 * Margins of the current prop
		 * @type {ComputedRef<Margins>}
		 */
		const margins = computed(() => ({
			top: xoneAttributesHandler.getScaledSize(attributes.value.margins.top, props.containerHeight, heightFactor.value) ?? 0,
			left: xoneAttributesHandler.getScaledSize(attributes.value.margins.left, props.containerWidth, widthFactor.value) ?? 0,
			bottom: xoneAttributesHandler.getScaledSize(attributes.value.margins.bottom, props.containerHeight, heightFactor.value) ?? 0,
			right: xoneAttributesHandler.getScaledSize(attributes.value.margins.right, props.containerWidth, widthFactor.value) ?? 0,
		}));

		/**
		 * calculate floating top
		 * @type {ComputedRef<string>}
		 */
		const floatingTop = computed(() => xoneAttributesHandler.getScaledPosition(attributes.value.floating.top, heightFactor.value));

		/**
		 * calculate floating leeft
		 * @type {ComputedRef<string>}
		 */
		const floatingLeft = computed(() => xoneAttributesHandler.getScaledPosition(attributes.value.floating.left, widthFactor.value));

		/**
		 * Check if disableedit
		 * @type {ComputedRef<boolean>}
		 */
		const isDisableEdit = computed(() => {
			if ((attributes.value.disableEdit ?? "") === "") return false;
			return xoneAttributesHandler.evalFormula(attributes.value.disableEdit, props.xoneDataObject.model);
		});

		/**
		 * Padding
		 * @type {ComputedRef<Paddings>}
		 */
		const paddings = computed(() => {
			return {
				top: xoneAttributesHandler.getScaledSize(attributes.value.paddings.top, props.containerHeight, heightFactor.value),
				right: xoneAttributesHandler.getScaledSize(attributes.value.paddings.right, props.containerWidth, widthFactor.value),
				bottom: xoneAttributesHandler.getScaledSize(attributes.value.paddings.bottom, props.containerHeight, heightFactor.value),
				left: xoneAttributesHandler.getScaledSize(attributes.value.paddings.left, props.containerWidth, widthFactor.value),
			};
		});

		/**
		 * Calculate the checkbox size
		 * @type {ComputedRef<string|null>}
		 */
		const checkboxSize = computed(() => {
			if (attributes.value.imgWidth) return xoneAttributesHandler.getScaledSize(attributes.value.imgWidth, props.containerWidth, widthFactor.value);
			else if (attributes.value.imageHeight)
				return xoneAttributesHandler.getScaledSize(attributes.value.imgHeight, props.containerHeight, widthFactor.value);
			return null;
		});

		// Execute postonchange method

		/**
		 * Last breadcrumb in stack
		 * @type {ComputedRef<import('../composables/AppDataHandler').Breadcrumb>}
		 */
		const lastBreadcrumb = inject("lastBreadcrumb");

		// Inject breadcrumbId info to child components
		const breadcrumbId = inject("breadcrumbId");

		nextTick(() => {
			if (!attributes.value.postOnchange) return;
			watch(
				() => lastBreadcrumb.value,
				async (newValue) => {
					if (newValue.id !== breadcrumbId) return;
					xoneAttributesHandler.executeMethod(attributes.value.postOnchange, props.xoneDataObject, attributes.value.name);
				}
			);
		});

		//
		// Designer mode Drag & Drop
		const onMouseEnter = () => dragAndDrop.mouseEnter(propElement.value);
		const onMouseLeave = () => dragAndDrop.mouseLeave(propElement.value);
		const onMouseDown = (e) => dragAndDrop.mouseDown(e);
		const onDragOver = (e) => dragAndDrop.mouseMove(e);

		return {
			propElement,
			displayScreenThresholds,
			attributes,
			inputType,
			controlWidth,
			controlHeight,
			margins,
			isDisableVisible,
			finalImgSize,
			floatingTop,
			floatingLeft,
			isDisableEdit,
			paddings,
			checkboxSize,
			isInput: computed(() => inputType.value),
			isButton: computed(() => attributes.value.type === "B"),
			isImage: computed(() => attributes.value.type === "IMG" || attributes.value.type === "PH" || attributes.value.type === "DR"),
			isContents: computed(() => attributes.value.type === "Z" || attributes.value.viewMode === "routerview"),
			isWeb: computed(() => attributes.value.type === "WEB"),
			isVideo: computed(() => attributes.value.type === "VD"),
			isBreadcrumb: computed(
				() =>
					attributes.value.viewMode === "breadcrumbview" ||
					attributes.value.viewMode === "breadcrumbsview" ||
					attributes.value.viewMode === "breadcumbview"
			),
			isDesigner: dragAndDrop.getIsDesigner(),
			isDragging: dragAndDrop.getIsDragging(),
			isDesignerPropSelected: computed(() => dragAndDrop.getSelectedControl()?.value === propElement.value),
			borderDesignSelected: dragAndDrop.getBorderSelected(),
			borderDesignOver: dragAndDrop.getBorderPropOver(),
			onMouseEnter,
			onMouseLeave,
			onMouseDown,
			onDragOver,
		};
	},
};
</script>

<style scoped>
.xone-prop {
	display: flex;
	position: relative;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	margin-top: var(--margin-top);
	margin-right: var(--margin-right);
	margin-bottom: var(--margin-bottom);
	margin-left: var(--margin-left);
	animation: fadeIn 0.3s;
}
</style>
