const locale = Intl.DateTimeFormat().resolvedOptions().locale; //navigator.language;

// Convert date to xone date format
if (!Date.prototype.toXoneDate)
	(function () {
		const pad = (number) => (number < 10 ? `0${number}` : number);
		Date.prototype.toXoneDate = function (type = "DT") {
			let value = this.getFullYear() + "-" + pad(this.getMonth() + 1) + "-" + pad(this.getDate());
			if (type === "DT") value += "T" + pad(this.getHours()) + ":" + pad(this.getMinutes());
			return value;
		};

		Date.prototype.toString = function () {
			return (
				this.getFullYear() +
				"-" +
				pad(this.getMonth() + 1) +
				"-" +
				pad(this.getDate()) +
				" " +
				pad(this.getHours()) +
				":" +
				pad(this.getMinutes()) +
				":" +
				pad(this.getSeconds())
			);
		};
	})();

// Month Days
if (!Date.prototype.monthDays)
	Date.prototype.monthDays = function () {
		return new Date(this.getFullYear(), this.getMonth() + 1, 0).getDate();
	};

if (!Date.prototype.firstWeekDayOfMonth)
	Date.prototype.firstWeekDayOfMonth = function () {
		return new Date(this.getFullYear(), this.getMonth(), 1).getDay();
	};

if (!Date.prototype.lastWeekDayOfMonth)
	Date.prototype.lastWeekDayOfMonth = function () {
		return new Date(
			this.getFullYear(),
			this.getMonth(),

			this.monthDays()
		).getDay();
	};

if (!Date.prototype.previusMonth)
	Date.prototype.previusMonth = function () {
		let year = this.getFullYear();
		let month = this.getMonth();
		if (month === 0) {
			month = 12;
			year--;
		}
		return new Date(`${year}-${month}-01`);
	};

if (!Date.prototype.nextMonth)
	(function () {
		Date.prototype.nextMonth = function () {
			let year = this.getFullYear();
			let month = this.getMonth() + 2;
			if (month > 12) {
				month = 1;
				year++;
			}
			return new Date(`${year}-${month}-01`);
		};
	})();

if (!Date.prototype.isSameDate)
	(function () {
		Date.prototype.isSameDate = function (date) {
			return this.getDate() === date.getDate() && this.getMonth() === date.getMonth() && this.getFullYear() === date.getFullYear();
		};
	})();

const weekStart = (region, language) => {
	const regionSat = "AEAFBHDJDZEGIQIRJOKWLYOMQASDSY".match(/../g);
	const regionSun = "AGARASAUBDBRBSBTBWBZCACNCODMDOETGTGUHKHNIDILINJMJPKEKHKRLAMHMMMOMTMXMZNINPPAPEPHPKPRPTPYSASGSVTHTTTWUMUSVEVIWSYEZAZW".match(
		/../g
	);
	const languageSat = ["ar", "arq", "arz", "fa"];
	const languageSun = "amasbndzengnguhehiidjajvkmknkolomhmlmrmtmyneomorpapssdsmsnsutatethtnurzhzu".match(/../g);

	return region
		? regionSun.includes(region)
			? 0
			: regionSat.includes(region)
			? 0 //6
			: 1
		: languageSun.includes(language)
		? 0
		: languageSat.includes(language)
		? 0 //6
		: 1;
};

/**
 * Get week start day depending on navigator language
 * @returns {number}
 */
export const weekStartLocale = () => {
	const parts = locale.match(/^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i);
	return weekStart(parts[4], parts[1]);
};

export const addDays = (date, days) => {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
};

/**
 * Get week days
 * @param {boolean} [isLongFormat]
 * @returns {Array<string>}
 */
export const getWeekDays = (isLongFormat = false) => {
	const weekdayDateMap = [...Array(8).keys()].map((e) => new Date(2021, 1, e));
	const weekDays = [];
	for (let i = weekStartLocale(); i < weekStartLocale() + 7; i++)
		weekDays.push(
			new Intl.DateTimeFormat(locale, {
				weekday: isLongFormat ? "long" : "short",
			}).format(weekdayDateMap[i])
		);

	return weekDays;
};
