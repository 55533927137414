class XoneSystemSettings {
	checkMarketUpdate() {}
	clearApplicationData() {}
	clearBitmapCache() {}
	clearJavascriptCache() {}
	getAndroidVersion() {}
	getApiLevel() {}
	getBrightness() {}
	getBrightnessMode() {}
	getDownloadsPath() {}
	getExternalStoragePath() {}
	getGalleryPath() {}
	getGrantedPermissions() {}
	getHardwareIds() {
		return { deviceIdCount: 1, deviceId0: localStorage.getItem("_deviceId"), androidId: localStorage.getItem("_deviceId") };
	}
	getnetworktime() {}
	getNotGrantedPermissions() {}
	getSharedUserId() {}
	getWallpaper() {}
	isAirplaneMode() {}
	isIgnoringBatteryOptimazations() {}
	isPasswordSecured() {}
	ispermissiongranted() {}
	requestIgnoringBatteryOptimazations() {}
	requestPermissions() {}
	setBrightness() {}
	setBrightnessMode() {}
	setWallpaper() {}
}

export default new XoneSystemSettings();
