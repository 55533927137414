<template>
	<div class="xone-picturemap">
		<!-- Zoom Buttons -->
		<div class="xone-picturemap-zoom">
			<div>
				<button
					@click="modifyZoom(0.5)"
					:style="{
						backgroundColor: (zoom < 5 && '#fff') || '#f4f4f4',
					}"
				>
					+
				</button>
				<button
					@click="zoom > 1 && modifyZoom(-0.5)"
					:style="{
						opacity: zoom <= 1 ? 0.6 : 1,
						backgroundColor: (zoom > 1 && '#fff') || '#f4f4f4',
					}"
				>
					-
				</button>
			</div>
			<input
				v-if="!hideRange"
				@click="$event.stopPropagation()"
				@mousemove="$event.stopPropagation()"
				type="range"
				orient="vertical"
				v-model="zoom"
				min="1"
				max="5"
				step=".25"
			/>
		</div>
		<!-- PictureMap Container -->
		<div
			ref="picturemapElement"
			class="xone-picturemap-container"
			:style="{
				height: controlHeight && `${zoom * controlHeight}px`,
				width: controlWidth && `${zoom * controlWidth}px`,
				'--picturemap-cursor': isGrabbing ? 'grabbing' : 'grab',
				'--picturemap-items-cursor': isGrabbing ? 'grabbing' : 'pointer',
			}"
		>
			<!-- PictureMap Container aspect-ratio Resized -->
			<div
				v-if="containerElementSize && !isLoading"
				class="xone-picturemap-items"
				:style="{
					height: containerElementSize.height && `${containerElementSize.height}px`,
					width: containerElementSize.width && `${containerElementSize.width}px`,
					backgroundImage: contentsImage?.path && `url(${contentsImage.path})`,
				}"
			>
				<!-- Items -->
				<template v-for="item in data" :key="`pictureMap-${attributes.name}-${item.index}`">
					<div
						class="xone-picturemap-item"
						v-if="item.scaledX && item.scaledY"
						:style="{
							left: item.scaledX + 'px',
							top: item.scaledY + 'px',
							width: item.scaledImgWidthOff && item.scaledImgWidthOff + 'px',
							height: item.scaledImgHeightOff && item.scaledImgHeightOff + 'px',
							zIndex: item.zIndex,
						}"
					>
						<!-- Item Text -->
						<div
							v-if="item.text"
							:style="{
								color: item.textColor && item.textColor,
								width: item.scaledImgWidthOff && item.scaledImgWidthOff + 'px',
								height: item.scaledImgHeightOff && item.scaledImgHeightOff + 'px',
							}"
						>
							{{ item.text }}
						</div>
						<!-- Item Image -->
						<button
							@click="onItemSelected(item)"
							:style="{
								width: item.scaledImgWidthOff && item.scaledImgWidthOff + 'px',
								height: item.scaledImgHeightOff && item.scaledImgHeightOff + 'px',
								backgroundImage: `url(${item.selected ? item.iconOn && item.iconOn : item.iconOff && item.iconOff})`,
								filter: item.selected ? item.filterOn && item.filterOn : (item.filterOff && item.filterOff) || item.filterOn,
							}"
						></button>
					</div>
				</template>
			</div>
			<!-- Loader -->
			<div v-else>
				<div class="xone-loader">
					<div></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, PropType, Ref, ComputedRef, ref, inject, nextTick, watch, onUnmounted } from "vue";

import { PropAttributes } from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
import { XoneControl, XoneView } from "../../../composables/XoneViewsHandler";
import { getImagePathAndSize } from "../../../composables/helperFunctions/ImageHelper";
import Image from "../Image.vue";
import { Color, HEX2RGB, Solver } from "../../../composables/helperFunctions/ColorHelper";
import XmlNode from "../../../composables/appData/Xml/JSONImpl/XmlNode";
export default {
	components: { Image },
	name: "PictureMap",

	props: {
		/**
		 * xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 * */
		xoneDataObject: { type: Object, required: true },

		/**
		 * attributes
		 * @type { PropType<PropAttributes>}
		 */
		attributes: { type: Object, default: null, required: true },

		controlWidth: { type: Number, default: 0 },

		controlHeight: { type: Number, default: 0 },
	},
	setup(props) {
		/**
		 * Contents
		 * @type {Ref<XoneDataCollection>}
		 */
		const contents = ref();

		/**
		 * xoneView
		 * @type {XoneView}
		 */
		const xoneView = inject("xoneView");

		const data = ref([]);

		/**
		 * contents Image
		 * @type {Ref<Promise<{path:string,imgSize:{width:number,height:number}}>}
		 */
		const contentsImage = ref();

		// Get contents Image
		onMounted(async () => {
			if (props.attributes.image) {
				contentsImage.value = await getImagePathAndSize(props.attributes.image, "files");
				if (props.attributes.image && !contentsImage.value)
					console.error("Not found: picturemap background image", props.attributes.name, props.attributes.image);
				calculateContainerAspectRatioSize();
			}
		});

		/**
		 * Scale Factor
		 * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
		 */
		const { widthFactor, heightFactor } = inject("scaleFactor");

		//
		// Calculate Element Size
		const containerElementSize = ref();

		const isLoading = ref(true);

		// Scale Factors
		let scaleFactorX;
		let scaleFactorY;

		/**
		 * Picturemap zoom
		 * @type {Ref<number>}
		 */
		const zoom = ref(1);

		/**
		 * Hide Range, atributo "hide-range" que oculta el rango del zoom que tiene el mapa
		 * @type{Ref<boolean>}
		 */
		const hideRange = ref(props.xoneDataObject?.getFieldPropertyValue(props.attributes.name, "hide-range") === "true");

		/**
		 * Add zoom
		 */
		const modifyZoom = async (value) => {
			zoom.value = Number(zoom.value) + value;
			if (zoom.value < 1) return (zoom.value = 1);
			if (zoom.value > 5) return (zoom.value = 5);
		};

		watch(
			() => zoom.value,
			(newValue, oldValue) => {
				if (newValue < 1 || newValue > 5) return;
				calculateContainerAspectRatioSize(0);

				contentsElement.scrollTo({
					left: contentsElement.scrollLeft + (contentsElement.offsetWidth * (newValue - oldValue)) / 2,
					top: contentsElement.scrollTop + (contentsElement.offsetHeight * (newValue - oldValue)) / 2,
					behavior: "auto",
				});
			}
		);

		//
		// Handler grab

		/**
		 * picturemapElement
		 * @type {Ref<HTMLElement>}
		 */
		const picturemapElement = ref();

		/**
		 * contents Element
		 * @type {HTMLElement}
		 */
		let contentsElement;

		let pos = { top: 0, left: 0, x: 0, y: 0 };

		let dragAllowedTimeout;
		const isGrabbing = ref(false);

		const mouseDownHandler = function (e) {
			pos = {
				// The current scroll
				left: contentsElement.scrollLeft,
				top: contentsElement.scrollTop,
				// Get the current mouse position
				x: e.clientX,
				y: e.clientY,
			};
			if (dragAllowedTimeout) clearInterval(dragAllowedTimeout);

			dragAllowedTimeout = setTimeout(() => {
				contentsElement.addEventListener("mousemove", mouseMoveHandler);
				contentsElement.addEventListener("mouseup", mouseUpHandler);
			}, 100);
		};

		const mouseMoveHandler = function (e) {
			if (e.buttons === 0) {
				mouseUpHandler();
				return;
			}
			if (!isGrabbing.value) isGrabbing.value = true;

			// How far the mouse has been moved
			const dx = e.clientX - pos.x;
			const dy = e.clientY - pos.y;

			// Scroll the element
			contentsElement.scrollTop = pos.top - dy;
			contentsElement.scrollLeft = pos.left - dx;
		};

		const mouseUpHandler = function () {
			setTimeout(() => (isGrabbing.value = false), 25);

			contentsElement.removeEventListener("mousemove", mouseMoveHandler);
			contentsElement.removeEventListener("mouseup", mouseUpHandler);
		};

		const mouseLeaveHandler = function () {
			setTimeout(() => (isGrabbing.value = false), 25);

			contentsElement.addEventListener("mousemove", mouseMoveHandler);
			contentsElement.addEventListener("mouseup", mouseUpHandler);
		};

		const mouseWheel = function (e) {
			if (e.deltaY < 0) modifyZoom(0.25);
			if (e.deltaY > 0 && zoom.value > 1) modifyZoom(-0.25);
			e.preventDefault();
		};

		onMounted(() => {
			contentsElement = picturemapElement.value?.parentElement;
			contentsElement.addEventListener("mousedown", mouseDownHandler);
			contentsElement.addEventListener("mouseleave", mouseLeaveHandler);
			contentsElement.addEventListener("wheel", mouseWheel);
		});

		onUnmounted(() => {
			contentsElement.removeEventListener("mousedown", mouseDownHandler);
			contentsElement.removeEventListener("mouseleave", mouseLeaveHandler);
			contentsElement.removeEventListener("wheel", mouseWheel);
		});

		/**
		 * Calculate container size
		 */
		const calculateContainerAspectRatioSize = async (timeout = 500) => {
			await nextTick();

			setTimeout(() => {
				const contentsImgSize = contentsImage.value?.imgSize;

				// Scale from image size
				if (contentsImgSize) {
					// Nota para mi yo del futuro: Si existe imagen de fondo en el contents, la tomo como referencia para sacar el factor de escalado de cada coordenada
					scaleFactorX = (zoom.value * props.controlWidth) / contentsImgSize.width;
					scaleFactorY = (zoom.value * props.controlHeight) / contentsImgSize.height;
				} else {
					// Nota para mi yo del futuro: No existe imagen, calculo el factor global de escalado de cada coordenada
					// Scale from width and  height scale factor
					scaleFactorX = widthFactor.value;
					scaleFactorY = heightFactor.value;
				}

				// Get min scale from width / height scale
				let scaleFactor = scaleFactorX < scaleFactorY ? scaleFactorX : scaleFactorY;

				// Set container aspect-ratio width / height
				containerElementSize.value = {
					width: (zoom.value * (props.controlWidth * scaleFactor)) / scaleFactorX,
					height: (zoom.value * (props.controlHeight * scaleFactor)) / scaleFactorY,
				};

				// Scale content items
				scaleItems();
			}, timeout);
		};

		watch(
			() => props.controlWidth,
			() => calculateContainerAspectRatioSize()
		);
		watch(
			() => props.controlHeight,
			() => calculateContainerAspectRatioSize()
		);

		// key attributes
		let xCoord;
		let yCoord;
		let iconOff;
		let iconOn;
		let iconWidth;
		let iconHeigth;
		let iconText;
		let iconTextColor;
		let iconMarkColor;
		let iconTouchColor;
		let circleRadius;

		nextTick(async () => {
			// Get contents
			if (!contents.value) {
				contents.value = await props.xoneDataObject.getContents(props.attributes.contents);
				props.xoneDataObject.addContents(props.attributes.name, contents.value);
			}

			// Get key attributes

			/**
			 * m_xmlNode
			 * @type {{m_xmlNode:XmlNode}}
			 */
			const { m_xmlNode } = contents.value;

			m_xmlNode.SelectNodes("prop").forEach((/** @type {XmlNode} */ e) => {
				if (e.getAttrValue("xcoord") === "true") xCoord = e.getAttrValue("name");
				if (e.getAttrValue("ycoord") === "true") yCoord = e.getAttrValue("name");
				if (e.getAttrValue("icon-mark") === "true") iconOff = e.getAttrValue("name");
				if (e.getAttrValue("icon-touch") === "true") iconOn = e.getAttrValue("name");
				if (e.getAttrValue("icon-mark-color") === "true") iconMarkColor = e.getAttrValue("name");
				if (e.getAttrValue("icon-touch-color") === "true") iconTouchColor = e.getAttrValue("name");
				if (e.getAttrValue("icon-width") === "true") iconWidth = e.getAttrValue("name");
				if (e.getAttrValue("icon-height") === "true") iconHeigth = e.getAttrValue("name");
				if (e.getAttrValue("icon-text") === "true") iconText = e.getAttrValue("name");
				if (e.getAttrValue("icon-text-color") === "true") iconTextColor = e.getAttrValue("name");
				if (e.getAttrValue("circle-radius") === "true") circleRadius = e.getAttrValue("name");
			});

			// Add Control to View
			const xoneControl = new XoneControl(props.attributes.name);
			xoneControl.refresh = refresh;
			xoneView.addControl(xoneControl);

			// Refresh contents
			refresh();
		});

		onUnmounted(() => {
			if (contents.value) contents.value.clear();
		});

		/** @type {Ref<boolean>} */
		const isBeforeEditExecuted = inject("isBeforeEditExecuted");

		//
		// Refresh contents
		const refresh = async () => {
			while (!isBeforeEditExecuted.value) await new Promise((resolve) => setTimeout(() => resolve(), 5));
			// Load contents data
			isLoading.value = true;

			await nextTick();

			// LoadAll
			await contents.value.loadAll(false);

			data.value = [];

			for (let i = 0; i < contents.value.length; i++) {
				const contentsItem = await contents.value.get(i);

				const dataValue = {};
				// Index
				dataValue.index = i;
				// Coords
				dataValue.xCoord = contentsItem[xCoord];
				dataValue.yCoord = contentsItem[yCoord];
				// text / textColor
				dataValue.text = contentsItem[iconText];
				dataValue.textColor = contentsItem[iconTextColor];
				if (iconOff) {
					const valueOff = await getImagePathAndSize(contentsItem[iconOff], "files");
					// Icon off width and height from image size
					if (!valueOff) return console.error("Not found: icon-mark", props.attributes.name, contentsItem[iconOff]);
					dataValue.iconOff = valueOff.path;
					dataValue.imgWidthOff = valueOff.imgSize.width;
					dataValue.imgHeightOff = valueOff.imgSize.height;
				}
				// Icon on width and height from image size
				if (iconOn)
					getImagePathAndSize(contentsItem[iconOn], "files").then((valueOn) => {
						if (!valueOn) return console.error("Not found: icon-touch", props.attributes.name, contentsItem[iconOn]);
						dataValue.iconOn = valueOn.path;
						dataValue.imgWidthOn = valueOn.imgSize.width;
						dataValue.imgHeightOn = valueOn.imgSize.height;
						if (circleRadius) {
							dataValue.imgWidthOn = contentsItem[circleRadius];
							dataValue.imgHeightOn = contentsItem[circleRadius];
							dataValue.xCoord -= dataValue.imgWidthOn / 2;
							dataValue.yCoord -= dataValue.imgHeightOn / 2;
						}
						scaleItems();
					});
				// Icon mark / touch color
				dataValue.iconMarkColor = contentsItem[iconMarkColor];
				dataValue.iconTouchColor = contentsItem[iconTouchColor];
				// Nota para mi yo del futuro: El color de la imagen debe de venir en negro para  convertirla aplicando filtros, de lo contrario el color va a salir un poco random...
				if (dataValue.iconMarkColor) {
					const rgb = HEX2RGB(dataValue.iconMarkColor);
					const color = new Color(rgb[0], rgb[1], rgb[2]);
					const solver = new Solver(color);
					const result = solver.solve();
					dataValue.filterOff = result.filter;
					if (result.loss > 10) console.warn("Color lost", dataValue.iconMarkColor, result.loss);
				}
				if (dataValue.iconTouchColor) {
					const rgb = HEX2RGB(dataValue.iconTouchColor);
					const color = new Color(rgb[0], rgb[1], rgb[2]);
					const solver = new Solver(color);
					const result = solver.solve();
					dataValue.filterOn = result.filter;
					if (result.loss > 10) console.warn("Color lost", dataValue.iconTouchColor, result.loss);
				}
				// Icon width and height from attribute
				if (iconWidth && iconHeigth) {
					dataValue.imgWidthOff = contentsItem[iconWidth];
					dataValue.imgHeightOff = contentsItem[iconHeigth];
				}

				data.value.push(dataValue);
			}

			// Scale Items
			scaleItems();

			// Select first item
			if (data.value.length !== 0) onItemSelected(data.value[0]);

			// Order items by size
			let i = 1;
			data.value.sort((a, b) => b.imgWidthOff * b.imgHeightOff - a.imgWidthOff * a.imgHeightOff).forEach((e) => (e.zIndex = i = i + 1));

			isLoading.value = false;
		};

		// Get Items Factor X
		const getItemFactorX = () => {
			let zoomFactor = 1;
			let scaledWidth = containerElementSize.value?.width ?? 0;
			let width = props.controlWidth / scaleFactorX;
			if (contentsImage.value?.imgSize && scaledWidth !== 0) {
				zoomFactor = width / contentsImage.value.imgSize.width;
			}
			return (scaledWidth / width) * zoomFactor;
		};

		// Get Items Factor Y
		const getItemFactorY = () => {
			let zoomFactor = 1;
			let scaledHeight = containerElementSize.value?.height ?? 0;
			let height = props.controlHeight / scaleFactorY;
			if (contentsImage.value?.imgSize && scaledHeight !== 0) {
				zoomFactor = height / contentsImage.value.imgSize.height;
			}
			return (scaledHeight / height) * zoomFactor;
		};

		// Scale all Items
		const scaleItems = () => data.value.forEach(scaleItem);

		// Scale Item
		const scaleItem = (item) => {
			item.scaledX = item.xCoord * getItemFactorX();
			item.scaledY = item.yCoord * getItemFactorY();

			item.scaledImgWidthOff = item.imgWidthOff * getItemFactorX();
			item.scaledImgHeightOff = item.imgHeightOff * getItemFactorY();
		};

		// On Item Selected
		const onItemSelected = async (item) => {
			if (isGrabbing.value) return;
			data.value.forEach((e) => (e.selected = false));
			item.selected = true;

			if (!contents.value) return;

			/**
			 * xoneDataObject
			 * @type {XoneDataObject}
			 */
			const xoneDataObject = await contents.value.get(item.index);

			if (xoneDataObject) xoneDataObject.ExecuteNode("selecteditem").catch(console.error);
		};

		return {
			picturemapElement,
			contentsImage,
			isLoading,
			containerElementSize,
			data,
			onItemSelected,
			zoom,
			hideRange,
			modifyZoom,
			isGrabbing,
		};
	},
};
</script>

<style scoped>
.xone-picturemap {
	overflow: auto;
	width: var(--contents-width);
	height: var(--contents-height);
}

.xone-picturemap-zoom {
	position: absolute;
	display: flex;
	flex-direction: row;
	top: 10px;
	left: 10px;
	z-index: 9999;
	border: 1px solid rgb(204, 204, 204);
	background-color: #fff;
	border-radius: 2px;
	pointer-events: all;
	margin-top: 10px;
}
.xone-picturemap-zoom > div {
	display: flex;
	flex-direction: column;
}

.xone-picturemap-zoom button {
	border: 1px solid rgb(204, 204, 204);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	font-size: 25px;
}

.xone-picturemap-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.xone-picturemap-container:hover {
	cursor: var(--picturemap-cursor);
}

.xone-picturemap-container > div {
	position: relative;
	animation: fadeIn 0.3s;
}

.xone-picturemap-item {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	width: 50px;
	height: 50px;
	font-size: 0.8rem;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	background-color: transparent;
	border: none;
	outline: none;
	pointer-events: visiblePainted;
	overflow: visible;
}

.xone-picturemap-item > div {
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 0.7rem;
	z-index: 999;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.xone-picturemap-zoom > div > button {
	cursor: pointer;
}

button {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	background-color: transparent;
	pointer-events: visiblePainted;

	width: 100%;
	height: 100%;
	border: none;
	outline: none;
}

button:hover {
	cursor: var(--picturemap-items-cursor);
}

div {
	background-repeat: no-repeat;
	background-position: center;
	/* background-size: contain; */
	background-size: 100% 100%;
	overflow: hidden;
}

input[type="range"][orient="vertical"] {
	-webkit-appearance: slider-vertical;
	width: 8px;
	height: 50px;
	margin: 5px;
	overflow: hidden;
}

input[type="range"][orient="vertical"]::-webkit-slider-thumb {
	cursor: ns-resize;
	box-shadow: -80px 0 0 80px lightgray;
}
</style>
