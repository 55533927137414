const { clipboard } = navigator;

class XoneClipboard {
  /**
   * getText
   * @returns {Promise<string>}
   */
  async getText() {
    return await clipboard.readText();
  }

  /**
   * setText
   * @param {string} value
   * @returns {Promise<void>}
   */
  async setText(value) {
    return await clipboard.writeText(value);
  }

  /**
   * clear
   * @returns {Promise<void>}
   */
  async clear() {
    return await clipboard.writeText("");
  }

  /**
   * hasSomething
   * @returns {Promise<boolean>}
   */
  async hasSomething() {
    return !!(await clipboard.readText());
  }
}

export default new XoneClipboard();
