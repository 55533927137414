let HubConnection, HubConnectionBuilder, LogLevel;

export class SignalR {
	/**
	 * _instance
	 * @type {SignalR}
	 */
	static _instance;

	/**
	 * @type {Map<string, {reconnect: boolean, hub:HubConnection}>}
	 */
	connections = new Map();

	constructor() {
		if (SignalR._instance) return SignalR._instance;

		SignalR._instance = this;
	}

	async connect(connectionName, url, token = "", skipNegotiation, transport) {
		if (!HubConnection) {
			const module = await await import("@microsoft/signalr");
			HubConnection = module.HubConnection;
			HubConnectionBuilder = module.HubConnectionBuilder;
			LogLevel = module.LogLevel;
		}
		let connection = this.connections.get(connectionName);
		if (connection) {
			if (connection.hub.state === "Connected") return;
		} else connection = {};

		connection.reconnect = true;
		connection.hub = new HubConnectionBuilder()
			.withUrl(url, { accessTokenFactory: () => token, skipNegotiation, transport })
			.configureLogging(LogLevel.Information)
			.build();

		async function start() {
			try {
				await connection.hub.start();
				console.log("Connectados al socket", connectionName, url);
			} catch (ex) {
				console.error(ex);
				setTimeout(start, 5000);
			}
		}
		connection.hub.onclose(async () => {
			if (!connection.reconnect) return;
			await start();
		});

		if (!this.connections.has(connectionName)) this.connections.set(connectionName, connection);
		await start();
	}

	disconnect(connectionName) {
		const connection = this.connections.get(connectionName);
		if (!connection) return;
		try {
			connection.reconnect = false;
			if (connection.hub.state === "Connected") connection.hub.stop();
		} catch {}
	}

	on(connectionName, eventName, callback) {
		const connection = this.connections.get(connectionName);
		if (!connection) throw new Error(`Connection ${connectionName} not found`);

		connection.hub.on(eventName, callback);
	}

	off(connectionName, eventName) {
		const connection = this.connections.get(connectionName);
		if (!connection) throw new Error(`Connection ${connectionName} not found`);

		connection.hub.off(eventName);
	}

	invoke(connectionName, methodName, value) {
		const connection = this.connections.get(connectionName);
		if (!connection) throw new Error(`Connection ${connectionName} not found`);

		connection.hub.invoke(methodName, value);
	}
}
