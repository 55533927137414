import { decode } from "js-base64";
import { parseXoneXml } from "./StringHelper";

/**
 * @param {string} stringSearch
 * @returns
 */
export const getUrlParams = (stringSearch) => {
	try {
		const search = location.href.slice(location.href.indexOf(stringSearch) + stringSearch.length);
		return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
			return key === "" ? value : decodeURIComponent(value);
		});
	} catch {
		return onErrorFunc("Error getting url params");
	}
};

export const getPreviewObjects = (sourcePath) => {
	const urlParams = getUrlParams("preview?");
	let xmlString,
		cssString,
		jsString,
		rWString = "-1",
		rHString = "-1",
		webLayoutString = "",
		scaleFontsizeString = "false",
		fontFactorString = "";
	try {
		const [xml, css, js, resolutionWidth, resolutionHeight, webLayout, scaleFontsize, fontFactor] = urlParams.v.split("|");

		if (xml)
			// @ts-ignore
			xmlString = decode(xml).decompress();
		if (css)
			// @ts-ignore
			cssString = decode(css).decompress();
		if (js)
			// @ts-ignore
			jsString = decode(js).decompress();
		if (resolutionWidth)
			try {
				// @ts-ignore
				rWString = Number(resolutionWidth);
			} catch {}

		if (resolutionHeight) rHString = Number(resolutionHeight);
		// @ts-ignore
		if (webLayout) webLayoutString = decode(webLayout.decompress());
		if (scaleFontsize) scaleFontsizeString = scaleFontsize;
		if (fontFactor) fontFactorString = fontFactor;
	} catch {}

	if (!xmlString || !xmlString.toString().includes("<group"))
		xmlString = `<coll name="test" notab="true"><group name="group1" id="1"></group></coll>`;

	/** @type {Document|null} */
	const doc = xmlString ? parseXoneXml(`<xml>${xmlString}</xml>`) : null;
	if (!cssString) cssString = "prop{}";
	if (webLayoutString && !doc.querySelector(`coll[name='${webLayoutString}']`)) webLayoutString = "";
	localStorage.setItem(sourcePath + "/default.css", cssString);

	window.isPreview = true;

	const fileManagerProperties = {};
	fileManagerProperties[`${sourcePath}/app.xml`] = `
                <xml>
                    <app prefix="gen" version="0.0.0.1" companycolor="#FFFFFF"
						resolution-height="${rHString}"
						resolution-width="${rWString}"
						web-layout="${webLayoutString}"
                        autologon="true"
                        entry-point="${doc ? doc.querySelector("coll").getAttribute("name") : ""}"
						scale-fontsize="${scaleFontsizeString}"
						web-font-factor="${fontFactorString}"
					>
						${cssString ? '<style url="default.css" />' : ""}
                    </app>
                </xml>`;
	fileManagerProperties[`${sourcePath}/mappings.xne`] = `
                <xml>
                    <collprops type="general">
                        <coll name="Empresas" sql="SELECT * FROM gen_empresas" objname="empresa" updateobj="empresa" progid="ASGestion.CASEmpresa">
                            <group name="General" id="1">
                                <prop name="CODIGO" visible="3" type="N" fieldsize="4" autosearch="true" />
                                <prop name="NOMBRE" visible="3" type="T" size="100" fieldsize="24" />
                            </group>
                        </coll>
                        <coll name="Usuarios" sql="SELECT * FROM gen_usuarios" objname="usuarios" updateobj="usuarios" progid="ASGestion.CASUser">
                        <group name="General" id="1">
                            <prop name="IDEMPRESA" visible="0" type="N" mapcol="Empresas" mapfld="ID" />
                            <prop name="LOGIN" visible="3" type="T" fieldsize="10" fixed-text="true" size="10" />
                            <prop name="PWD" type="T" fieldsize="10" fixed-text="true" size="10" />
                            <prop name="NOMBRE" visible="3" type="T" fieldsize="30" fixed-text="true" size="30" />
                            <prop name="USERTYPE" type="T" fieldsize="30" fixed-text="true" size="50" />
                        </group>
                        <create>
                            <action name="setval" field="IDEMPRESA" value="##ENTID##" />
                        </create>
                        </coll>
                        ${xmlString}
                    </collprops>
                </xml>
                `;
	fileManagerProperties[`${fileManagerProperties}/default.css`] = cssString;

	return {
		fileManager: {
			readFileAsync: async (filePath) => {
				return fileManagerProperties[filePath];
			},
		},
		sWA: async (AppData, Ui, Self, SelfDataColl, User, Func, Arguments) => {
			let appData = AppData,
				ui = Ui,
				self = Self,
				selfDataColl = SelfDataColl,
				user = User,
				XArguments = Arguments;

			return new Promise((resolve) => eval("(async()=>{" + "\n\n" + jsString + "\n" + Func + "\n\nresolve();\n})()"));
		},
	};
};
