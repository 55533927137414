export class SwipeHandler {
	_xThreshhold = 75; // slide this._xThreshhold
	_allowedTime = 500; // ms allowed to swipe
	_yThreshhold = 75; // slide this._yThreshhold

	/**
	 * Constructor
	 * @param {Function} callbackFunction
	 */
	constructor(callbackFunction) {
		this.callbackFunction = callbackFunction;
	}

	/**
	 * Touch Start
	 * @param {TouchEvent} e
	 */
	touchStart(e) {
		if (!this.isSwipeable(e.target)) return e.stopPropagation();
		this.touchobj = e.changedTouches[0];
		this.dist = 0;
		this.startX = this.touchobj.pageX;
		this.startY = this.touchobj.pageY;
		// get start time
		this.startTime = new Date().getTime();
	}

	/**
	 * Touch End
	 * @param {TouchEvent} e
	 */
	touchEnd(e) {
		if (!this.isSwipeable(e.target)) return e.stopPropagation();
		this.touchobj = e.changedTouches[0];
		this.dist = this.touchobj.pageX - this.startX;
		// get swipe time
		this.elapsedTime = new Date().getTime() - this.startTime;
		if (
			this.elapsedTime > this._allowedTime ||
			Math.abs(this.dist) < this._xThreshhold ||
			Math.abs(this.touchobj.pageY - this.startY) > this._yThreshhold
		)
			return;

		this.callbackFunction(this.dist < 0 ? 1 : -1);
	}

	/**
	 * isSwipeable
	 * @param {HTMLElement} element
	 * @return {boolean}
	 */
	isSwipeable(element) {
		do {
			if (element === this.touchElement) return true;
			if (element.getAttribute("swipeable") === "false") return false;
			element = element.parentElement;
		} while (element);
		return true;
	}

	/**
	 * Initializate
	 * @param {HTMLElement} touchElement
	 */
	init(touchElement) {
		this.touchElement = touchElement;
		this.touchElement.addEventListener("touchstart", this.touchStart.bind(this), { passive: true });
		this.touchElement.addEventListener("touchend", this.touchEnd.bind(this), {
			passive: true,
		});
	}

	clear() {
		// clear events
		this.touchElement.removeEventListener("touchstart", this.touchStart);
		this.touchElement.removeEventListener("touchend", this.touchEnd);
	}
}
