<template>
  <teleport v-if="!isOnline" to="body">
    <!-- Toast Container -->
    <div class="xone-online">
      <!-- Toast Items -->
      <div>Offline</div>
    </div>
  </teleport>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";

export default {
  setup() {
    onMounted(() => {
      window.addEventListener("online", setOnline);
      window.addEventListener("offline", setOffline);
    });

    onUnmounted(() => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    });

    const setOnline = () => (isOnline.value = true);
    const setOffline = () => (isOnline.value = false);

    const isOnline = ref(navigator.onLine);

    return { isOnline };
  },
};
</script>

<style scoped>
.xone-online {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 999;
  opacity: 0.5;
}

.xone-online > div {
  margin-top: 10px;
  padding: 10px 30px;
  border: 3px white solid;
  background-color: red;
  font-size: 1.2rem;
  color: white;
  border-radius: 5px;
  text-align: center;
}
</style>
