<template>
  <div
    class="xone-vertical-bar-wrapper"
    :style="{
      height: controlHeight + 'px',
      width: controlWidth + 'px',
    }"
  >
    <div
      class="xone-vertical-bar-aside"
      :style="{ width: controlWidth + 'px' }"
    ></div>
    <div
      class="xone-vertical-bar"
      :style="{
        width: controlWidth + 'px',
        backgroundColor: attributes.bgColor,
      }"
    >
      <!-- Marks -->
      <div
        v-for="item in horizontalMarks"
        :key="item"
        class="xone-vertical-mark"
        :style="{ bottom: item.percentage + '%' }"
      >
        <div
          class="xone-vertical-mark-line"
          :style="{ borderColor: attributes.foreColor }"
        ></div>
        <!-- Mark Value -->
        <span :style="{ color: attributes.foreColor }">{{ item.value }}</span>
      </div>
      <div
        class="xone-bar-value"
        :style="{
          height: currentValuePercentage + '%',
          backgroundColor: attributes.barColor,
        }"
      ></div>
    </div>
    <!-- Bar Value -->
    <div
      class="xone-vertical-bar-aside"
      :style="{ width: controlWidth + 'px' }"
    ></div>
  </div>
</template>

<script>
import { computed, onMounted, PropType, ref, watch } from "@vue/runtime-core";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
export default {
  name: "Contents",
  props: {
    /**
     * xoneDataObject
     * @type {import('@vue/runtime-core').PropType<XoneDataObject>}
     * */
    xoneDataObject: { type: Object, required: true },
    controlHeight: { type: Number, default: 0 },
    controlWidth: { type: Number, default: 0 },
    /** attributes
		 @type {PropType<import('../../../composables/XoneAttributesHandler').PropAttributes>}
		*/
    attributes: { type: Object, required: true },
  },
  setup(props) {
    const currentValuePercentage = computed(
      () =>
        (props.xoneDataObject.model[props.attributes.name] /
          props.attributes.maxValue) *
        100
    );
    const horizontalMarks = ref([]);

    watch(
      () => props.attributes.interval,
      () => drawMarkers()
    );
    watch(
      () => props.attributes.minValue,
      () => drawMarkers()
    );
    watch(
      () => props.attributes.maxValue,
      () => drawMarkers()
    );
    watch(
      () => props.attributes.barColor,
      () => drawMarkers()
    );
    watch(
      () => props.attributes.foreColor,
      () => drawMarkers()
    );
    watch(
      () => props.attributes.bgColor,
      () => drawMarkers()
    );

    const drawMarkers = () => {
      try {
        horizontalMarks.value = [];
        for (
          let i = props.attributes.minValue;
          i <= props.attributes.maxValue;
          i += props.attributes.interval
        ) {
          horizontalMarks.value.push({
            value: i,
            percentage: (i / props.attributes.maxValue) * 100,
          });
        }
      } catch {}
    };

    onMounted(() => drawMarkers());
    return {
      currentValuePercentage,
      horizontalMarks,
    };
  },
};
</script>
<style scoped>
.xone-vertical-bar-wrapper {
  display: flex;
  flex-direction: column;
}
.xone-vertical-bar {
  position: relative;
  background-color: black;
  flex-grow: 1;
  flex-shrink: 1;
  transition: background 0.5s;
}
.xone-vertical-mark {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.xone-vertical-mark-line {
  border-bottom: 2px solid white;
  flex-grow: 1;
  flex-shrink: 1;
  z-index: 1;
}
span {
  color: white;
  top: calc(0.5rem);
  position: relative;
}
.xone-vertical-bar-aside {
  height: 10px;
  background-color: gray;
}
.xone-bar-value {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 30px;
  bottom: 0;
  transition: height 0.5s, background-color 1s;
  z-index: 1;
}
</style>
