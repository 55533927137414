import { Exception } from "../Exceptions/Exception";
import IllegalArgumentException from "../Exceptions/IllegalArgumentException";
import StringBuilder from "./StringBuilder";
import StringUtils from "./StringUtils";
import TextUtils from "./TextUtils";


export class Utils {
    public static DEFAULT_BGCOLOR = "#FFB22C1B";
    public static DEFAULT_FORECOLOR = "#FF000000";
    public static FAIL_EXITAPP = "##EXITAPP##";
    public static FAIL_EXIT = "##EXIT##";
    public static FAIL_LOGIN = "##LOGIN_START##";

    public static MACRO_LOGIN_START = Utils.FAIL_LOGIN;
    public static MACRO_LOGIN_USERCOLL = "##LOGIN_USERCOLL##";
    public static MACRO_LOGIN_NEWUSER = "##LOGIN_NEWUSER##";
    public static MACRO_LOGIN_NEWPASS = "##LOGIN_NEWPASS##";
    public static MACRO_LOGIN_COLL_OBJECT_INDEX = "##LOGIN_COLL_OBJECT_INDEX##";
    public static MACRO_STARTREPLICA = "##STARTREPLICA##";
    public static TAG_DATABASE_LOG = "XOneDatabase";
    public static PROP_ATTR_EMBED = "embed";

    static isDebuggable(m_context: any): boolean {
        return false;
    }

    // Valores por defecto
    public static PROP_DEFAULT_FIELDSIZE = 14;
    public static PROP_DEFAULT_LABELWIDTH = 10;
    public static PROP_DEFAULT_CONTENTLINES = 10;
    public static TRUE_VALUE = "true";
    public static FALSE_VALUE = "false";
    public static DEFAUT_FILES_PATH = "files/";
    public static DATE_SEPARATOR = "/";
    public static HOUR_SEPARATOR = ":";
    public static DANISH_HOUR_SEPARATOR = "\\.";

    // Valores para los lastexcuted app
    public static LAST_EXECUTED_APP_FILE_NAME = "lastexecuted.ini";
    public static LAST_EXECUTED_APP_FIELD_NAME = "appname";
    public static LAST_EXECUTED_USER_FIELD_NAME = "user";

    public static LICENSE_FIELD_NAME = "License";
    public static SERVER_FIELD_NAME = "ServerADDR";

    static TAG_FRAMEWORK = "XOneFramewrok";
    static TAG_MAPPINGFILE = "mappings";
    static TAG_APPNODE = "app.xml";
    static EMPTY_STRING = "";
    static PROP_NAME = "prop";
    static PROP_ATTR_FRAME = "frame";
    static PROP_ATTR_CONTENTNAME = "contents";
    static PROP_ATTR_GROUP = "group";
    static PROP_ATTR_NAME = "name";
    static PROP_ATTR_VISIBLE = "visible";
    static PROP_ATTR_TITLE = "title";
    static PROP_ATTR_INDEX: string = "index";
    static PROP_ATTR_TYPE = "type";

    // Mascaras de bits
    public static MASK_EDIT_VISIBLE = 1;
    public static MASK_LIST_VISIBLE = 2;
    public static MASK_CONTENT_VISIBLE = 4;
    public static MASK_DROPDOWN_VISIBLE = 8;
    // Macros
    public static MACRO_DEFAULT = "##DEFAULT##";
    public static MACRO_TAG = "##";

    // Cadenas de la colección
    public static COLL_COLLPROPS = "collprops";
    public static COLL_COLL = "coll";
    public static COLL_LOGIN_COLL = "login-coll";
    public static COLL_LOGOFF_COLL = "logoff-coll";
    public static PLATFORM_NAME = "platform";

    public static DebugLog(tag: string, text: string) {

    }

    public static formatKey(nodeName: string, defaultAttr: string, defaultAttrValue: string): string {
        return "##" + nodeName + "##" + defaultAttr + "##" + defaultAttrValue + "##";
    }

    public static CheckNullParameters(sFunctionName: string, ...FunctionParams: any) {
        if (FunctionParams == null) {
            throw new IllegalArgumentException("Error de ejecución. Faltan parámetros para '" + sFunctionName + "'");
        }
    }

    public static CheckIncorrectParamCount(sFunctionName: string, FunctionParams: Object[], nExpectedCount) {
        if (FunctionParams == null) {
            if (nExpectedCount == 0) {
                return;
            }
            throw new IllegalArgumentException("Error de ejecución. Número incorrecto de parámetros para '" + sFunctionName + "'");
        }
        if (FunctionParams.length != nExpectedCount) {
            throw new IllegalArgumentException("Error de ejecución. Número incorrecto de parámetros para '" + sFunctionName + "'");
        }
    }

    public static CheckIncorrectParamRange(sFunctionName: string, FunctionParams: Object[], nMinimumParameterCount: number, nMaximumParameterCount: number): void {
        if (FunctionParams == null) {
            if (nMinimumParameterCount == 0) {
                return;
            }
            throw new IllegalArgumentException("Error de ejecución. Número incorrecto de parámetros para '" + sFunctionName + "'");
        }
        if (FunctionParams.length < nMinimumParameterCount) {
            throw new IllegalArgumentException("Error de ejecución. Número incorrecto de parámetros para '" + sFunctionName + "'");
        }
        if (FunctionParams.length > nMaximumParameterCount && nMaximumParameterCount != -1) {
            throw new IllegalArgumentException("Error de ejecución. Número incorrecto de parámetros para '" + sFunctionName + "'");
        }
    }

    public static setLastAppExecutedField(m_context: any, sUserName: string, arg2: string) {
        // TODO: Luis buscar un mecanismo para persistir datos en el navegador
    }

    public static getThrowableMessage(t: Exception): string {
        if (t == null) {
            return Utils.EMPTY_STRING;
        }
        let sExceptionMessage = t.getClass().getSimpleName();
        let sMessage = t.getMessage();
        if (!TextUtils.isEmpty(sMessage)) {
            sExceptionMessage = sExceptionMessage + "(): " + sMessage;
        }
        return sExceptionMessage;
    }


}
