import { getAppPath } from "./ImageHelper";

export const addFontFaces = () => {
	const newStyle = document.createElement("style");
	const appPath = getAppPath();
	newStyle.appendChild(
		document.createTextNode(`@font-face {
	  font-family: "Roboto";
	  src: url(${window.location.origin}${appPath ? `/${appPath}` : ""}/fonts/Roboto-Medium.ttf);
	}
		`)
	);

	document.head.appendChild(newStyle);
};
