import { XoneControl } from "./XoneViewsHandler";

/** @type {number} */
let _widthFactor = 1;

/** @type {number} */
let _heightFactor = 1;

/**
 * setWidthFactor
 * @param {number} value
 */
export const setWidthFactor = (value) => (_widthFactor = value || 1);

/**
 * setHeightFactor
 * @param {number} value
 */
export const setHeightFactor = (value) => (_heightFactor = value || 1);

export default class XoneAnimation {
  /** @type {HTMLDivElement} */
  _target;

  /** @type {Object} */
  _options;

  /** @type {Object[]} */
  _keyframes;

  /** @type {Function} */
  _callback;

  /** @type {boolean} */
  _animate = false;

  stop() {
    this._animate = false;
    if (this._target) this._target.animate({});
  }

  animate() {
    if (!this._animate) return;
    this._animate = false;
    if (!this._target) return;

    if (this._callback) setTimeout(() => this._callback(), this._options.duration);
    this._target.animate(this._keyframes, this._options);
  }

  initAnimate() {
    if (this._animate) return;
    this._options = { duration: 300, fill: "forwards" };
    this._keyframes = [];
    this._animate = true;
    this._callback = null;
    Promise.resolve().then(() => this.animate());
  }

  /**
   * setTarget
   * @param {XoneControl} control
   */
  setTarget(control) {
    this.initAnimate();
    this._target = control.element || document.querySelector(`[xone-name="${control.name}"]`);
    return this;
  }

  /**
   * setDuration
   * @param {number} duration
   */
  setDuration(duration) {
    this.initAnimate();
    this._options.duration = duration;
    return this;
  }

  setCircularReveal() {
    // Aquí se muestra el objeto con una animación rara, ya la trabajaré porque tampoco me parece interesante ni importante
    return this;
  }

  /**
   * setRelativeScaleX
   * @param {number} scale
   */
  setRelativeScaleX(scale) {
    this.initAnimate();
    this._keyframes.push({ transform: `scaleX(${scale})` });
    return this;
  }

  /**
   * setRelativeScaleY
   * @param {number} scale
   */
  setRelativeScaleY(scale) {
    this.initAnimate();
    this._keyframes.push({ transform: `scaleY(${scale})` });
    return this;
  }

  setScaleX(scale) {
    return this.setRelativeScaleX(scale);
  }

  setScaleY(scale) {
    return this.setRelativeScaleY(scale);
  }

  /**
   * setRelativeScaleY
   * @param {number} x
   */
  setRelativeX(x) {
    this.initAnimate();
    this._keyframes.push({
      transform: `translateX(${x * _widthFactor}px)`,
    });
    return this;
  }

  /**
   * setRelativeScaleY
   * @param {number} y
   */
  setRelativeY(y) {
    this.initAnimate();
    this._keyframes.push({
      transform: `translateY(${y * _heightFactor}px)`,
    });
    return this;
  }

  // Las posiciones las trabajo todas como relativas, para trabajar con posiciones absolutas lo que tendría que hacer el usuario es setear el objeto como floating, no tiene sentido esta animación si no es floating...
  setX(x) {
    this.setRelativeX(x, true);
    // this._keyframes.push({
    //   position: "absolute",
    // });
    return this;
  }

  setY(y) {
    this.setRelativeY(y);
    // this._keyframes.push({
    //   position: "absolute",
    // });
    return this;
  }

  setZ() {
    return this;
  }

  /**
   * setWidth
   * @param {string} width
   */
  setWidth(width) {
    this.initAnimate();

    if (!isNaN(Number(width))) width = Number(width) * _widthFactor + "px";
    else if (width.includes("p") || width.includes("px")) width = Number(width.replace("px", "").replace("p", "")) * _widthFactor + "px";

    this._keyframes.push({ width });
    return this;
  }

  /**
   * setHeight
   * @param {string} height
   */
  setHeight(height) {
    this.initAnimate();

    if (!isNaN(Number(height))) height = Number(height) * _heightFactor + "px";
    else if (height.includes("p") || height.includes("px")) height = Number(height.replace("px", "").replace("p", "")) * _heightFactor + "px";

    this._keyframes.push({ height });
    return this;
  }

  /**
   * setAlpha
   * @param {number} alpha
   */
  setAlpha(alpha) {
    this.initAnimate();
    this._keyframes.push({ opacity: alpha });
    return this;
  }

  /**
   * setEndCallback
   * @param {Function} callback
   */
  setEndCallback(callback) {
    this._callback = callback;
  }

  /**
  setRepeatMode(value) {
   * 
   * @param {number} value 
   */
  setRepeatMode(value) {
    this.initAnimate();
    if (value === -1) this._options.iterations = "Infinity";
    else if (!isNaN(Number(value))) this._options.iterations = value;
    return this;
  }

  /**
   * setRotation
   * @param {number} value
   */
  setRotation(value) {
    this.initAnimate();
    this._keyframes.push({
      transform: `rotate(${value}deg)`,
    });
    return this;
  }

  setRelativeRotation(value) {
    return this.setRotation(value);
  }

  // TODO: ver que animar en cada caso, de momento meto algo ahí por defecto pero no tengo ni idea de lo que anima android tampoco
  setInterpolation(value) {
    switch (value) {
      case "AccelerateDecelerateInterpolator":
        return (this._options.easing = "ease");
      case "AccelerateInterpolator":
        return (this._options.easing = "ease-in");
      case "AnticipateInterpolator":
        return (this._options.easing = "ease-out");
      case "AnticipateOvershootInterpolator":
        return (this._options.easing = "ease-in-out");
      case "BounceInterpolator":
        return (this._options.easing = "cubic-bezier(0.42,0,0.58,1)");
      case "CycleInterpolator":
        return (this._options.easing = "cubic-bezier(0.42,0,0.58,1)");
      case "DecelerateInterpolator":
        return (this._options.easing = "cubic-bezier(0.42,0,0.58,1)");
      case "FastOutLinearInInterpolator":
        return (this._options.easing = "cubic-bezier(0.42,0,0.58,1)");
      case "FastOutSlowInInterpolator":
        return (this._options.easing = "cubic-bezier(0.42,0,0.58,1)");
      case "LinearInterpolator":
        return (this._options.easing = "linear");
      case "LinearOutSlowInInterpolator":
        return (this._options.easing = "cubic-bezier(1,1,.5,.5);");
      case "OvershootInterpolator":
        return (this._options.easing = "cubic-bezier(0,0,1,1);");
      default:
        return (this._options.easing = "");
    }
  }
}
