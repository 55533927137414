/**
 * XoneFileManager
 */
class XoneFileManager {
  /**
   * fileExists
   * @param {string} filePath
   */
  fileExists(filePath) {
    filePath = filePath.replace("source/", "/"); // TODO: el filePath viene mal concateado, hay que quitar el source

    const http = new XMLHttpRequest();
    http.open("GET", filePath, false);
    http.send();

    const res = http.status !== 404 ? 0 : -1; // Parece ser que si se devuelve 0 es que existe, tengo que hablar con ésta gente para que me de el contacto de su camello, yo también quiero gozar así!
    return res;
  }

  /**
   * download
   * @param {string} filePath
   */
  download(filePath) {
    filePath = filePath.replace("/source/", "/"); // TODO: el filePath viene mal concateado, hay que quitar el source

    const element = document.createElement("a");
    element.setAttribute("href", filePath);
    element.setAttribute("target", "_blank");
    element.style.display = "none";

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  /**
   * asyncDownload
   * @param {string} filePath
   */
  async asyncDownload(filePath) {
    await this.download(filePath);
  }

  // TODO: crear backend con una llamada para devolver la lista de directorios y ficheros
  listDirectories() {
    return [];
  }

  // TODO: crear backend con una llamada para devolver la lista de directorios y ficheros
  listFiles() {
    return [];
  }
}

export default XoneFileManager;
