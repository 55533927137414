<template>
  <div
    v-if="isDesigner"
    class="xone-designer-drag-container"
    :style="{
      '--image-back': `url(${appPath}/assets/arrow-up.png)`,
      '--image-right': `url(${appPath}/assets/arrow-right.png)`,
      '--image-down': `url(${appPath}/assets/arrow-down.png)`,
      '--image-left': `url(${appPath}/assets/arrow-left.png)`,
    }"
  >
    <!-- Containers name's -->
    <div class="xone-designer-drag" v-if="isDesigner && isDragging">
      <!-- Selected container -->
      <template v-if="containerSelected">
        <div
          :style="{
            position: 'absolute',
            zIndex: 9999999,
            left: containersInfo.find((e) => e.name === containerSelected).left + 'px',
            top: containersInfo.find((e) => e.name === containerSelected).top + 'px',
            width: containersInfo.find((e) => e.name === containerSelected).width + 'px',
            height: containersInfo.find((e) => e.name === containerSelected).height + 'px',
            outline: '3px solid blue',
          }"
        ></div>
      </template>
      <!-- Control over -->
      <div
        v-if="location"
        :style="{
          background: 'rgba(211,211,211, .3)',
          position: 'absolute',
          outline: border,
          outlineOffset: '-5px',
          top: location.top + 'px',
          left: location.left + 'px',
          width: location.width + 'px',
          height: location.height + 'px',
        }"
      ></div>

      <!-- Move up -->
      <div
        v-if="location"
        class="xone-designer-drag-move move-up"
        @mouseenter="moveCursorTo('up')"
        @mouseleave="moveCursorTo('')"
        :style="{
          top: location.top + 'px',
          left: location.left + selectorSize.x + 'px',
          width: location.width - selectorSize.x * 2 + 'px',
          height: selectorSize.y + 'px',
        }"
      ></div>
      <!-- Move bottom -->
      <div
        v-if="location"
        class="xone-designer-drag-move move-down"
        @mouseenter="moveCursorTo('bottom')"
        @mouseleave="moveCursorTo('')"
        :style="{
          position: 'absolute',
          top: location.top + location.height - selectorSize.y + 'px',
          left: location.left + selectorSize.x + 'px',
          width: location.width - selectorSize.x * 2 + 'px',
          height: selectorSize.y + 'px',
        }"
      ></div>
      <!-- Move left -->
      <div
        v-if="location"
        class="xone-designer-drag-move move-left"
        @mouseenter="moveCursorTo('left')"
        @mouseleave="moveCursorTo('')"
        :style="{
          top: location.top + 'px',
          left: location.left + 'px',
          width: selectorSize.x + 'px',
          height: location.height + 'px',
        }"
      ></div>
      <!-- Move right -->
      <div
        v-if="location"
        class="xone-designer-drag-move move-right"
        @mouseenter="moveCursorTo('right')"
        @mouseleave="moveCursorTo('')"
        :style="{
          position: 'absolute',
          top: location.top + 'px',
          left: location.left + location.width - selectorSize.x + 'px',
          width: selectorSize.x + 'px',
          height: location.height + 'px',
        }"
      ></div>
      <!-- Cursor -->
      <div
        v-if="cursorCoordinates"
        :style="{
          zIndex: 9999999,
          outline: '1px black solid',
          position: 'absolute',
          width: cursorCoordinates.width + 'px',
          height: cursorCoordinates.height + 'px',
          background: 'red',
          transition: 'all .3s',
          top: cursorCoordinates.top + 'px',
          left: cursorCoordinates.left + 'px',
          opacity: cursorCoordinates.opacity,
        }"
      ></div>
      <template v-for="containerInfo in containersInfo" :key="containerInfo.name">
        <div
          class="xone-designer-drag-containers"
          :style="{
            left: containerInfo.positionX + 'px',
            top: containerInfo.positionY + 'px',
            width: containerInfo.name.length * 10 + 'px',
            background: containerInfo.name === containerSelected ? 'blue' : 'white',
            color: containerInfo.name === containerSelected ? 'white' : 'black',
          }"
          @mouseenter="onMouseEnter(containerInfo.name)"
          @mouseleave="onMouseLeave()"
        >
          {{ containerInfo.name }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, ref, Ref, watch } from "vue";
import dragAndDrop from "../../composables/DragAndDrop";
import { getAppPath } from "../../composables/helperFunctions/ImageHelper";

export default {
  setup() {
    const isDesigner = dragAndDrop.getIsDesigner();

    const isDragging = dragAndDrop.getIsDragging();

    const selectedControl = dragAndDrop.getSelectedControl();

    const dragControl = dragAndDrop.getDragControl();

    /** @type {Ref<string>} */
    const containerSelected = ref();

    /** @type {Ref<Object>} */
    const location = ref();

    /** @type {Ref<Object>} */
    const cursorCoordinates = ref();

    /** @type {Ref<Array<Object>>} */
    const containersInfo = ref([]);

    watch(
      () => dragControl.value,
      (newValue) => {
        if (!isDesigner.value || !isDragging.value) return;
        containersInfo.value = [];
        containerSelected.value = "";
        location.value = null;
        cursorCoordinates.value = {
          ...cursorCoordinates.value,
          width: 0,
          height: 0,
          opacity: 0,
        };
        try {
          /** @type {HTMLDivElement} */
          let element = newValue;
          location.value = {
            top: element.getBoundingClientRect().top - 5,
            left: element.getBoundingClientRect().left - 5,
            width: element.getBoundingClientRect().width + 10,
            height: element.getBoundingClientRect().height + 10,
            isContainer: element.classList.contains("xone-frame"),
          };
          while (element) {
            if (element.classList.contains("xone-frame")) {
              const containerInfo = {
                control: element,
                name: element.getAttribute("xone-name"),
                top: element.getBoundingClientRect().y,
                left: element.getBoundingClientRect().x,
                width: element.getBoundingClientRect().width,
                height: element.getBoundingClientRect().height,
                positionY: element.getBoundingClientRect().y,
                positionX: element.getBoundingClientRect().x,
              };
              containersInfo.value.push(containerInfo);
            }

            if (element === selectedControl.value) {
              containersInfo.value = [];
              location.value = null;
            }
            element = element.parentElement;
          }
          // Put container box in final position
          containersInfo.value = containersInfo.value.map((e) => {
            const containerAux = containersInfo.value.find(
              (aux) =>
                aux.name !== e.name &&
                aux.positionX >= e.positionX &&
                aux.positionX + aux.name.length * 10 <= e.positionX + e.name.length * 10 &&
                aux.positionY >= e.positionY &&
                aux.positionY + 21 <= e.positionX + 21
            );
            if (containerAux) {
              e.positionY += 21;
            }
            return e;
          });
        } catch {}
      }
    );
    return {
      isDesigner: dragAndDrop.getIsDesigner(),
      isDragging: dragAndDrop.getIsDragging(),
      location,
      containersInfo,
      containerSelected,
      onMouseEnter: (name) => {
        cursorCoordinates.value = {
          ...cursorCoordinates.value,
          width: 0,
          height: 0,
          opacity: 0,
        };
        containerSelected.value = name;
      },
      onMouseLeave: () => (containerSelected.value = null),
      cursorCoordinates,
      border: dragAndDrop.getBorderOver(),
      moveCursorTo: (value) => {
        if (dragControl.value.classList.contains("xone-frame") && containersInfo.value.length > 1)
          containerSelected.value = containersInfo.value[1].name;
        else containerSelected.value = containersInfo.value[0].name;

        let left = location.value.left;
        let top = location.value.top;
        let width = location.value.width;
        let height = location.value.height;

        if (dragControl.value.classList.contains("xone-frame") && containersInfo.value.length === 1) {
          /** @type {HTMLDivElement} */
          let element = containersInfo.value[0].control;

          if (["bottom", "right"].includes(value)) element = element.lastElementChild;
          else element = element.firstElementChild;

          left = element.getBoundingClientRect().left;
          width = element.getBoundingClientRect().width;
          top = element.getBoundingClientRect().top;
          height = element.getBoundingClientRect().height;
        }

        const size = 10;

        switch (value) {
          case "left":
            return (cursorCoordinates.value = {
              left: left,
              top: top,
              width: size,
              height: height,
              opacity: 1,
            });
          case "up":
            return (cursorCoordinates.value = {
              left: left,
              top: top,
              width: width,
              height: size,
              opacity: 1,
            });
          case "right":
            return (cursorCoordinates.value = {
              left: left + width,
              top: top,
              width: size,
              height: height,
              opacity: 1,
            });
          case "bottom":
            return (cursorCoordinates.value = {
              left: left,
              top: top + height,
              width: width,
              height: size,
              opacity: 1,
            });
        }
      },
      selectorSize: computed(() => {
        let x = 30;
        let y = 30;
        if (location.value.width / 2 < x) x = location.value.width / 2;
        if (location.value.height / 2 < y) y = location.value.height / 2;

        if (!location.value.isContainer) {
          if (location.value.width > location.value.height) {
            x = location.value.width / 4;
            y = location.value.height / 2;
          } else {
            x = location.value.width / 2;
            y = location.value.height / 4;
          }
        }
        return { x, y };
      }),
      appPath: getAppPath(),
    };
  },
};
</script>

<style scoped>
.xone-designer-drag-container {
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}

.xone-designer-drag {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 20px;
  background-color: transparent;
  outline: var(--border);
  max-height: 100vh;
  pointer-events: none;
  z-index: 99999;
}

.xone-designer-drag-containers {
  pointer-events: all;
  border: 1px black solid;
  border-radius: 3px;
  background: white;
  position: absolute;
  height: 20px;
  text-align: center;
  font-size: 14px;
}

.xone-designer-drag-selected {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 20px;
  background-color: transparent;
  outline: 3px blue solid;
  max-height: 100vh;
  z-index: 99999;
}

.xone-designer-drag-move {
  opacity: 0.25;
  outline: 1px dotted blue;
  background: white;
  pointer-events: all;
  position: absolute;
  background-repeat: no-repeat;

  background-position: center;
  background-size: 16px 16px;
}

.xone-designer-drag-move:hover {
  background-color: cyan;
}

.move-up {
  background-image: var(--image-back);
}
.move-right {
  background-image: var(--image-right);
}
.move-down {
  background-image: var(--image-down);
}
.move-left {
  background-image: var(--image-left);
}
</style>
