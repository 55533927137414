<template>
  <div
    v-if="isDesigner"
    class="xone-designer-selected-container"
    :style="{
      opacity: opacity,
      '--image-parent': `url(${appPath}/assets/parent.png)`,
      '--image-move': `url(${appPath}/assets/move.png)`,
    }"
  >
    <div
      v-if="isDesigner && location"
      style="position: absolute; outline: 3px solid white; transition: all 0.3s"
      :style="{
        top: location.top + 'px',
        left: location.left + 'px',
        width: location.width + 'px',
        height: location.height + 'px',
      }"
    ></div>
    <div
      class="xone-designer-selected"
      v-if="isDesigner && location"
      :style="{
        top: location.top + 'px',
        left: location.left + 'px',
        width: location.width + 'px',
        height: location.height + 'px',
        '--border': border,
      }"
    >
      <div
        v-if="!isGroup"
        class="xone-designer-selected-resizer"
        @mouseleave="onMouseLeave()"
      >
        <div></div>
      </div>
      <div
        class="xone-designer-selected-options"
        :style="{
          top: location.top > 25 ? '-25px' : '0px',
          //top: '0px',
        }"
      >
        <div class="xone-designer-selected-options-name">{{ controlName }}</div>
        <div
          v-if="!isGroup"
          class="xone-designer-selected-option-parent"
          @click="onParentButtonClick"
        ></div>
        <div
          v-if="!isGroup"
          style="z-index: 9999999"
          @mousedown="onDragStart"
          @mouseup="onDragEnd"
          @touchstart="onDragStart"
          @touchend="onDragEnd"
          class="xone-designer-selected-option-move"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import dragAndDrop from "../../composables/DragAndDrop";
import { getAppPath } from "../../composables/helperFunctions/ImageHelper";

export default {
  setup() {
    const selectedControl = dragAndDrop.getSelectedControl();
    const controlName = ref("");
    const isGroup = ref(false);

    const opacity = ref(1);

    const location = ref();

    watch(
      () => selectedControl.value,
      (element) => {
        try {
          if (!element) return (location.value = null);
          controlName.value = element.getAttribute("xone-name");
          isGroup.value = element.getAttribute("group") === "true";
          location.value = {
            top: element.getBoundingClientRect().top,
            left: element.getBoundingClientRect().left,
            width: element.getBoundingClientRect().width,
            height: element.getBoundingClientRect().height,
          };
        } catch {
          location.value = null;
        }
      }
    );

    const onParentButtonClick = () => {
      const element = selectedControl.value;
      /**
       * @type {HTMLDivElement}
       */
      const parentElement = element?.parentNode?.parentNode;
      if (!parentElement || !parentElement.classList.contains("xone-frame"))
        return;
      dragAndDrop.setSelectedControl(parentElement);
    };

    const onDragStart = (/** @type{MouseEvent} **/ e) => {
      opacity.value = 0.3;
      dragAndDrop.setIsDragging(true);
      document.body.style.cursor = "move";
      document.body.addEventListener("mouseup", onDragEnd);
      document.body.addEventListener("touchend", onDragEnd);
      // e.dataTransfer.dropEffect = "move";
      // e.dataTransfer.setDragImage(selectedControl?.value, 0, 0);
    };

    const onDragEnd = (/** @type{MouseEvent} **/ e) => {
      opacity.value = 1;
      // e.dataTransfer.clearData();
      dragAndDrop.setIsDragging(false);
      document.body.style.cursor = "default";
      document.body.removeEventListener("mouseup", onDragEnd);
      document.body.removeEventListener("touchend", onDragEnd);
    };

    return {
      controlName,
      location,
      isDesigner: dragAndDrop.getIsDesigner(),
      border: dragAndDrop.getBorderSelected(),
      onMouseLeave: () => {
        // dragAndDrop.setSelectedControl(null);
        // dragAndDrop.setSelectedControlName(null);
      },
      onParentButtonClick,
      onDragStart,
      onDragEnd,
      isGroup,
      opacity,
      appPath: getAppPath(),
    };
  },
};
</script>

<style scoped>
.xone-designer-selected-container {
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}

.xone-designer-selected {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 20px;
  background-color: transparent;
  outline: var(--border);
  max-height: 100vh;
  z-index: 99999;
}

.xone-designer-selected-resizer {
  display: flex;
  position: absolute;
  right: -12.5px;
  bottom: -12.5px;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  pointer-events: all;
  cursor: nw-resize;
}
.xone-designer-selected-resizer > div {
  background: black;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  border: 1px solid white;
}

.xone-designer-selected-options {
  position: absolute;
  display: flex;
  right: 1px;
  pointer-events: all;
}

.xone-designer-selected-options div {
  height: 20px;
  width: 20px;
  border: none;
  color: white;
  margin-left: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  background-color: whitesmoke;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xone-designer-selected-options div:hover {
  outline: 1px solid #4cabd5;
}

.xone-designer-selected-options-name {
  width: auto !important;
  color: #1f3c6e !important;
  padding: 0 5px !important;
  background-color: white !important;
}
.xone-designer-selected-options-name:hover {
  outline: none !important;
}

.xone-designer-selected-option-parent {
  background-image: var(--image-parent);
  cursor: pointer;
}

.xone-designer-selected-option-move {
  background-image: var(--image-move);
  cursor: move;
}
</style>